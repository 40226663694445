<template>
  <div class="home">
    <v-subheader class="d-flex justify-space-between align-center">
      <v-col lg="4" cols="12">
        <v-form class="mt-5">
          <v-text-field
            rounded
            outlined
            dense
            color="#106b42"
            placeholder="Search advert campaigns with company name"
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            @keyup.esc="search = ''"
            v-on:input="filterAdverts"
          />
        </v-form>
      </v-col>
      <v-btn
        color="#106b42"
        class="rounded-lg create-btn"
        @click="validateCreate"
      >
        Create Ad Campaign
      </v-btn>
    </v-subheader>

    <v-row>
      <v-col lg="12">
        <v-data-table
          caption="Advertisments Campaigns"
          :headers="headers"
          :items="adverts"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.isActive`]="{ item }">
            <v-switch
              v-model="item.isActive"
              inset
              color="#106b42"
              disabled
            ></v-switch>
          </template>

          <template v-slot:[`item.view_action`]="{ item }">
            <v-btn class="edit-btn" color="#2A7EF5" @click="validateView(item)"
              >View</v-btn
            >
          </template>

          <template v-slot:[`item.edit_action`]="{ item }">
            <v-btn class="edit-btn" color="#106b42" @click="validateEdit(item)"
              >Edit</v-btn
            >
          </template>

          <template v-slot:[`item.delete_action`]="{ item }">
            <v-btn class="edit-btn" color="red" @click="validateDelete(item)"
              >Delete</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="createDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Create New IIFP Staff</span>
        </v-card-title>
        <v-form @submit.prevent="createCampaign" ref="createForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-file-input
                    @change="logFile($event)"
                    :rules="rules.file"
                    label="Select Ad Media File"
                    outlined
                    dense
                    required
                    prepend-icon="mdi-folder"
                    accept="image/jpeg, image/png, video/mp4"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="newCampaign.organization"
                    :rules="rules.name"
                    outlined
                    label="Company*"
                    required
                    hint="Company / Organization / Entity Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="newCampaign.redirectUrl"
                    outlined
                    :rules="rules.name"
                    label="URL*"
                    required
                    hint="Campaign Redirect URL"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    :rules="rules.name"
                    outlined
                    name="input-7-4"
                    label="Campaign Description*"
                    hint="Describe the product"
                    required
                    v-model="newCampaign.description"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="
                (createDialog = false),
                  (newCampaign = {}),
                  (fileToUpload = null)
              "
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="creating"
              color="#106b42"
              text
              @click="createCampaign"
            >
              <b>CREATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewDialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Advert Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="12"
                v-if="
                  currentAdvert.thumbUrl != null &&
                  (currentAdvert.thumbUrl.includes('jpg') ||
                    currentAdvert.thumbUrl.includes('png') ||
                    currentAdvert.thumbUrl.includes('jpeg'))
                "
              >
                <div class="avatar">
                  <img :src="sortFile(currentAdvert.thumbUrl)" alt="" />
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="12"
                v-if="
                  currentAdvert.thumbUrl != null &&
                  (currentAdvert.thumbUrl.includes('mp4') ||
                    currentAdvert.thumbUrl.includes('MP4'))
                "
              >
                <div class="video-player">
                  <video-player
                    class="the-vid-play"
                    :videoURL="currentAdvert.thumbUrl"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentAdvert.organization"
                  :rules="rules.name"
                  disabled
                  label="Company"
                  required
                  hint="Company / Organization / Entity Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentAdvert.redirectUrl"
                  disabled
                  :rules="rules.name"
                  label="URL"
                  required
                  hint="Campaign Redirect URL"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-textarea
                  :rules="rules.name"
                  disabled
                  name="input-7-4"
                  label="Campaign Description"
                  hint="Description of the product"
                  required
                  v-model="currentAdvert.description"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="(viewDialog = false), (currentAdvert = {}), (fileType = '')"
          >
            <b>CLOSE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Advert Campaign</span>
        </v-card-title>
        <v-form>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-file-input
                    @change="logFile($event)"
                    :rules="rules.name"
                    label="Select Ad Media File"
                    outlined
                    dense
                    prepend-icon="mdi-folder"
                    accept="image/jpeg, image/png, video/mp4"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="currentAdvert.organization"
                    :rules="rules.name"
                    outlined
                    label="Company*"
                    required
                    hint="Company / Organization / Entity Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="currentAdvert.redirectUrl"
                    outlined
                    :rules="rules.name"
                    label="URL*"
                    required
                    hint="Campaign Redirect URL"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    :rules="rules.name"
                    outlined
                    name="input-7-4"
                    label="Campaign Description*"
                    hint="Describe the product"
                    required
                    v-model="currentAdvert.description"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-switch
                    v-model="currentAdvert.isActive"
                    inset
                    color="#106b42"
                    :label="currentAdvert.isActive ? 'Active' : 'Inactive'"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="
                (editDialog = false),
                  (currentAdvert = {}),
                  (fileToUpload = null)
              "
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn :loading="editing" color="#106b42" text @click="editAdvert">
              <b>UPDATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" persistent max-width="350px">
      <v-card>
        <v-card-title class="text-h5" color="red">
          Remove Advert?
        </v-card-title>

        <v-card-text>
          Are you sure you want to remove this current advertisment by (<strong
            >{{ currentAdvert.organization }}</strong
          >) from IIFP Staff Advert Pool?.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteDialog = false), (currentAdvert = {})"
          >
            <b>CANCEL</b>
          </v-btn>

          <v-btn :loading="deleting" color="red" text @click="deleteAdvert">
            <b>DELETE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="1000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import VideoPlayer from "../../components/VideoPlayer";

export default {
  name: "Advertisments",
  components: {
    VideoPlayer,
  },
  data() {
    return {
      currentAdmin: {},
      currentToken: "",
      search: "",
      currentAdvert: {},
      loading: false,
      creating: false,
      editing: false,
      deleting: false,
      createDialog: false,
      viewDialog: false,
      editDialog: false,
      deleteDialog: false,
      successSnackbar: false,
      successMsg: "",
      failureSnackbar: false,
      errorMsg: "",
      headers: [
        { text: "Entity", value: "organization" },
        { text: "URL", value: "redirectUrl", sortable: false },
        { text: "Status", value: "isActive", sortable: true },
        { text: "", value: "view_action", sortable: false },
        { text: "", value: "edit_action", sortable: false },
        { text: "", value: "delete_action", sortable: false },
      ],
      adverts: [],
      newCampaign: {},
      fileType: "",
      fileToUpload: null,
      rules: {
        name: [(val) => (val || "").length > 0 || "This field is required"],
        file: [
          (value) =>
            !value ||
            value.size < 30000000 ||
            "Document size should be less than 30 MB!",
          (value) =>
            !value ||
            value.type === "image/jpg" ||
            value.type === "image/jpeg" ||
            value.type === "image/png" ||
            value.type === "video/mp4" ||
            "Only image and video files allowed!",
        ],
      },
    };
  },
  computed: {
    formIsValid() {
      return (
        this.newCampaign.organization &&
        this.newCampaign.redirectUrl &&
        this.newCampaign.description &&
        this.newCampaign.thumb
      );
    },
  },
  created() {
    this.currentAdmin = JSON.parse(localStorage.getItem("user"));
    this.currentToken = localStorage.getItem("token");

    //fetch advert campaigns
    this.fetchAdverts();
  },
  methods: {
    fetchAdverts() {
      //get adverts
      axios
        .get("advert/get", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.adverts = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    filterAdverts() {
      //check for search
      if (this.search.length > 0) {
        //check if search matches any data for each staff
        this.adverts = this.adverts.filter((advert) => {
          return advert.organization
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      } else {
        //clear adverts
        this.adverts = [];

        //fetch adverts
        this.fetchAdverts();
      }
    },
    sortFile(file) {
      if (file != "" && file != null) {
        if (file.includes("mp4") || file.includes("MP4")) {
          this.fileType = "video";
          return "https://firebasestorage.googleapis.com/v0/b/iifp-lms.appspot.com/o/Images%2Fvideo_placeholder.png?alt=media&token=2e165e95-a7c3-47c4-8532-feaa525b264d";
        } else {
          this.fileType = "image";
          return file;
        }
      }
    },
    logFile(e) {
      if (e != null) {
        console.log(e);
        this.newCampaign.thumb = e;
        this.fileToUpload = e;
      }
    },
    validateCreate() {
      if (this.currentAdmin.role === "SuperAdmin") {
        //open dialog
        this.createDialog = true;
      } else {
        //show error
        this.errorMsg = "You do not have permission to create advert campaigns";
        this.failureSnackbar = true;
      }
    },
    createCampaign() {
      if (this.$refs.createForm.validate() && this.newCampaign.thumb != null) {
        //start loading
        this.creating = true;

        //extract file
        const formData = new FormData();
        formData.append("organization", this.newCampaign.organization);
        formData.append("redirectUrl", this.newCampaign.redirectUrl);
        formData.append("description", this.newCampaign.description);
        formData.append("thumb", this.newCampaign.thumb);

        //api call
        axios
          .post("advert/create", formData, {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.creating = false;

            //show success
            this.successMsg = "Ad Campaign created successfully";
            this.successSnackbar = true;

            //close dialog
            this.createDialog = false;

            //reset form validation
            this.$refs.createForm.reset();

            //clear new advert form
            this.newCampaign = {};
            this.fileToUpload = null;

            //clear adverts list
            this.adverts = [];

            //fetch adverts
            this.fetchAdverts();
          })
          .catch((error) => {
            //stop loading
            this.creating = false;

            console.log(error.response.data);
            this.errorMsg = error.response.data.message;
            this.failureSnackbar = true;
          });
      } else {
        this.errorMsg = "Please add campaign Ad Thumbnail";
        this.failureSnackbar = true;
      }
    },
    validateView(advert) {
      //set data
      this.currentAdvert = advert;

      //show details dialog
      this.viewDialog = true;
    },
    validateEdit(advert) {
      if (this.currentAdmin.role === "SuperAdmin") {
        //set current advert
        this.currentAdvert = advert;

        //open dialog
        this.editDialog = true;
      } else {
        //show error
        this.errorMsg = "You do not have permission to edit staff";
        this.failureSnackbar = true;
      }
    },
    editAdvert() {
      //start loading
      this.editing = true;

      //create form
      const formData = new FormData();
      formData.append("organization", this.currentAdvert.organization);
      formData.append("redirectUrl", this.currentAdvert.redirectUrl);
      formData.append("description", this.currentAdvert.description);
      formData.append("isActive", this.currentAdvert.isActive);

      //thumb change
      if (this.fileToUpload != null) {
        formData.append("thumb", this.fileToUpload);
      }

      //update admin status
      axios
        .put(`advert/edit/${this.currentAdvert.id}`, formData, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.editing = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //nullify current action advert
          this.currentAdvert = {};
          this.fileToUpload = null;

          //close dialog
          this.editDialog = false;

          //clear list
          this.adverts = [];

          //fetch advert
          this.fetchAdverts();
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.editing = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    validateDelete(advert) {
      if (this.currentAdmin.role === "SuperAdmin") {
        //set current action advert
        this.currentAdvert = advert;

        //open dialog
        this.deleteDialog = true;
      } else {
        //show error
        this.errorMsg = "You do not have permission to delete staff";
        this.failureSnackbar = true;
      }
    },
    deleteAdvert() {
      //start loading
      this.deleting = true;

      //delete admin
      axios
        .delete(`advert/remove/${this.currentAdvert.id}`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.deleting = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //remove item from positions
          let index = this.adverts.findIndex(
            (item) => item.id == this.currentAdvert.id
          );
          this.adverts.splice(index, 1);

          //nullify current action admin
          this.currentAdvert = {};

          //close dialog
          this.deleteDialog = false;
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.deleting = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.position-instruction {
  color: #9e9e9e;
}

.new-position {
  display: inline-block;
  text-align: start;

  .the-chip {
    margin-right: 10px;
    display: inline-block;
  }
}

.edit-btn {
  color: #ffffff;
}

.avatar {
  text-align: center;
  margin: 20px auto;

  img {
    max-height: 300px;
    width: auto;
    border-radius: 10px;
  }
}

.create-btn {
  color: #ffffff;
}

.thumb {
  width: 70px;
  height: auto;
}
</style>
