import axios from "axios";

//base url
axios.defaults.baseURL = "https://api.iifpmobile.com.ng/api/v1/";

//test url
//axios.defaults.baseURL = "http://localhost:5000/api/v1/";

// //headers
// axios.defaults.headers.common["Authorization"] =
//   "Bearer " + localStorage.getItem("token");
