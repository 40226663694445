<template>
  <v-app>
    <div class="background"></div>
    <div class="loading">
      <div class="sk-flow">
        <div class="sk-flow-dot"></div>
        <div class="sk-flow-dot"></div>
        <div class="sk-flow-dot"></div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "LoadingPage",
  data: () => ({
    loading: true,
    failureSnackbar: false,
    errorMsg: "",
  }),
  methods: {
    checkStatus() {
      //timeout
      setTimeout(() => {
        if (!localStorage.getItem("token")) {
          //go to login page
          this.$router.replace("/authenticate");
        } else {
          //go to dashboard page
          this.$router.replace("/dashboard");
        }
      }, 5000);
      //check if prop user is null
    },
  },
  created() {
    this.checkStatus();
  },
};
</script>
<style scoped>
.background {
  background: #106b42 !important;
  height: 100vh;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.loading {
  align-content: center;
  align-items: center;
  align-self: center;
  margin: auto;
  padding: 100px;
  border-bottom: 1px solid #eee;
}
</style>
