<template>
  <v-card class="mx-auto" max-width="1920" tile>
    <v-img height="200" src="../../assets/images/profile_banner.jpg"></v-img>
    <v-row style="margin: 2.5%; position: absolute; top: 130px">
      <v-list-item>
        <v-list-item-avatar size="150">
          <img
            :src="sortAvatar(currentAdmin.avatar)"
            :alt="currentAdmin.username"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title" style="margin-top: 20px"
            >{{ currentAdmin.firstName }} {{ currentAdmin.lastName }}
            <strong>( {{ currentAdmin.username }} )</strong></v-list-item-title
          >
          <v-list-item-subtitle
            >{{ currentAdmin.position }} |
            {{ currentAdmin.email }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "Profile",

  data() {
    return {
      currentAdmin: {},
      currentToken: "",
      loading: false,
    };
  },
  created() {
    this.currentAdmin = JSON.parse(localStorage.getItem("user"));
    this.currentToken = localStorage.getItem("token");
  },
  methods: {
    sortAvatar(avatar) {
      if (avatar == null || avatar == "") {
        return "https://firebasestorage.googleapis.com/v0/b/iifp-lms.appspot.com/o/Images%2Favatar.png?alt=media&token=ca7838dc-f13b-41bf-b087-1980283d6f60";
      } else {
        return avatar;
      }
    },

    updateProfile() {
      this.loading = true;
    },
    logout() {
      //remove token from local storage
      localStorage.clear();

      //go home
      this.$router.replace("/");
    },
  },
};
</script>
<style scoped>
.background {
  background: #106b42 !important;
  height: 100vh;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.loading {
  align-content: center;
  align-items: center;
  align-self: center;
  margin: auto;
  padding: 100px;
  border-bottom: 1px solid #eee;
}
</style>
