<template>
  <div class="home">
    <v-subheader class="d-flex justify-space-between align-center">
      <v-col lg="4" cols="12">
        <v-form class="mt-5">
          <v-text-field
            rounded
            outlined
            dense
            color="#106b42"
            placeholder="Search members with member number"
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            @keyup.esc="search = ''"
            v-on:input="filterMembers"
          />
        </v-form>
      </v-col>
      <v-btn color="#106b42" class="rounded-lg create-btn">
        <export-excel
          :data="json_data"
          :fields="json_fields"
          worksheet="Members Worksheet"
          name="members_export.xls"
        >
          Export To Excel
        </export-excel>
      </v-btn>

      <v-btn
        color="#106b42"
        class="rounded-lg create-btn"
        @click="validateActivationDialog()"
      >
        ACTIVATE SUBSCRIPTION FOR ALL
      </v-btn>

      <v-btn
        color="#106b42"
        class="rounded-lg create-btn"
        @click="uploadDialog = true"
      >
        UPLOAD MEMBERS
      </v-btn>
    </v-subheader>

    <v-row>
      <v-col lg="12">
        <v-data-table
          caption="IIFP Members"
          :headers="headers"
          :items="members"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.view_action`]="{ item }">
            <v-btn class="edit-btn" color="#2A7EF5" @click="validateView(item)"
              >View</v-btn
            >
          </template>

          <template v-slot:[`item.edit_action`]="{ item }">
            <v-btn class="edit-btn" color="#106b42" @click="validateEdit(item)"
              >Edit</v-btn
            >
          </template>

          <template v-slot:[`item.delete_action`]="{ item }">
            <v-btn class="edit-btn" color="red" @click="validateDelete(item)"
              >Delete</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="uploadDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Upload New IIFP Members</span>
        </v-card-title>
        <v-form @submit.prevent="uploadMembers" ref="uploadForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-file-input
                    @change="logFile($event)"
                    :rules="rules.name"
                    label="Select Excel File"
                    outlined
                    dense
                    prepend-icon="mdi-folder"
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
            <div class="instruction">
              <img
                max-width="300px"
                src="../../assets/images/member_sample.jpeg"
              />
            </div>
            <small
              >*Please Upload Excel document in the format displayed above. Also
              members who already exist will be ignored from the new
              additions</small
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(uploadDialog = false), (newMembers = '')"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="uploading"
              color="#106b42"
              text
              @click="uploadMembers"
              :disabled="!formIsValid"
            >
              <b>CREATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Member Profile Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <div class="avatar">
                  <v-avatar size="80">
                    <img :src="sortAvatar(currentActiveMember.avatar)" alt="" />
                  </v-avatar>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveMember.firstName"
                  outlined
                  dense
                  disabled
                  label="First Name"
                  required
                  hint="Legal First Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveMember.otherName"
                  outlined
                  dense
                  disabled
                  label="Middle Name"
                  required
                  hint="Legal Middle Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveMember.lastName"
                  outlined
                  dense
                  disabled
                  label="Last Name"
                  required
                  hint="Legal Last Name / Surname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveMember.username"
                  outlined
                  dense
                  disabled
                  label="Username"
                  type="text"
                  hint="Unique Username"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveMember.email"
                  outlined
                  dense
                  disabled
                  label="Email"
                  type="text"
                  hint="Email Address"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveMember.phone"
                  outlined
                  dense
                  disabled
                  label="Phone"
                  type="text"
                  hint="Phone Number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveMember.occupation"
                  outlined
                  dense
                  disabled
                  label="Occupation"
                  type="text"
                  hint="Occupation"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveMember.placeOfWork"
                  outlined
                  dense
                  disabled
                  label="Place of Work"
                  type="text"
                  hint="Place of Work"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <div class="extra-btn">
                  <img
                    max-width="80px"
                    src="../../assets/images/documents_btn.png"
                    @click="validateDocumentDialog"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <div class="extra-btn">
                  <img
                    max-width="80px"
                    src="../../assets/images/subsciption_btn.png"
                    @click="validateSubscriptionDialog"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="(viewDialog = false), (currentActiveMember = {})"
          >
            <b>CLOSE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >Edit {{ currentActiveMember.memberNumber }}</span
          >
        </v-card-title>
        <v-form>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="currentActiveMember.memberNumber"
                    outlined
                    dense
                    label="Member Number"
                    required
                    hint="Update Member Number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-select
                    v-model="currentActiveMember.memberCategory"
                    :items="categories"
                    :label="currentActiveMember.memberCategory"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(editDialog = false), (currentActiveMember = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn :loading="editing" color="#106b42" text @click="editMember">
              <b>UPDATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5" color="red">
          Remove Member?
        </v-card-title>

        <v-card-text>
          Are you sure you want to remove member with Member Number: (<strong>{{
            currentActiveMember.memberNumber
          }}</strong
          >) from IIFP. Member will no longer be able to access any of IIFP
          platforms
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteDialog = false), (currentActiveMember = {})"
          >
            <b>CANCEL</b>
          </v-btn>

          <v-btn :loading="deleting" color="red" text @click="deleteMember">
            <b>DELETE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="#106b42" dark>
        <v-card-text>
          Fetching Profile, please stand by . . .
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="documentDialog" persistent max-width="400px">
      <v-card max-width="600" class="mx-auto">
        <v-toolbar color="#106b42" dark>
          <v-toolbar-title>Member Documents</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon>
            <v-icon @click="documentDialog = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-list subheader two-line>
          <v-list-item
            v-for="file in currentActiveMember.documents"
            :key="file.name"
          >
            <v-list-item-avatar>
              <v-icon dark>mdi-clipboard-text</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="file.name"></v-list-item-title>

              <v-list-item-subtitle v-text="file.type"></v-list-item-subtitle>

              <v-list-item-subtitle
                v-text="file.dateAdded"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon @click="deleteItem(file)" color="red lighten-1"
                  >mdi-delete</v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon @click="downloadItem(file)" color="blue lighten-1"
                  >mdi-download</v-icon
                >
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#106b42" text @click="addDocumentDialog = true">
            <b>ADD</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDocumentDialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Document</span>
        </v-card-title>
        <v-form @submit.prevent="uploadDocument" ref="uploadDocumentForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="newDocument.name"
                    outlined
                    dense
                    label="Document Name"
                    required
                    hint="Name of Document/Certification/Award"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="newDocument.type"
                    outlined
                    dense
                    name="input-7-4"
                    label="Document Description"
                    hint="Type / Desription of document"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-file-input
                    @change="logDocumentFile($event)"
                    :rules="rules.document"
                    label="Select Document File"
                    outlined
                    dense
                    prepend-icon="mdi-clipboard-text"
                    accept="application/pdf"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>

            <small
              >*Please Note: Documents uploaded to a member's profile will be
              visible as a certification.</small
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(addDocumentDialog = false), (newDocument = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="addingDocument"
              color="#106b42"
              text
              @click="uploadDocument"
            >
              <b>PROCEED</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="subscriptionDialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Subscription Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  :value="sortStatusData(currentActiveMember.subscription)"
                  outlined
                  dense
                  disabled
                  label="Status"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :value="sortSubDateData(currentActiveMember.subscription)"
                  outlined
                  dense
                  disabled
                  label="Subscription Date"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :value="sortExpDateData(currentActiveMember.subscription)"
                  outlined
                  dense
                  disabled
                  label="Expiry Date"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="activatingSubscription"
            color="#106b42"
            text
            @click="activateSubscription(currentActiveMember)"
          >
            <b>ACTIVATE SUBSCRIPTION</b>
          </v-btn>
          <v-btn color="red" text @click="subscriptionDialog = false">
            <b>CLOSE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="allActivationDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5" color="red">
          Activate Subscription For All?
        </v-card-title>

        <v-card-text>
          Are you sure you want to activate subscription for all members active
          on IIFP Mobile app.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" text @click="allActivationDialog = false">
            <b>CANCEL</b>
          </v-btn>

          <v-btn
            :loading="activatingAll"
            color="green darken-1"
            text
            @click="activateSubscriptionForAll()"
          >
            <b>ACTIVATE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deletingDocumentDialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card color="#106b42" dark>
        <v-card-text>
          Deleting Document, please stand by . . .
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="1000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Members",
  data() {
    return {
      currentAdmin: {},
      currentToken: "",
      search: "",
      uploading: false,
      editing: false,
      activatingSubscription: false,
      deleting: false,
      addingDocument: false,
      loadingDialog: false,
      uploadDialog: false,
      viewDialog: false,
      editDialog: false,
      deleteDialog: false,
      deletingDocumentDialog: false,
      documentDialog: false,
      addDocumentDialog: false,
      subscriptionDialog: false,
      allActivationDialog: false,
      activatingAll: false,
      successSnackbar: false,
      successMsg: "",
      failureSnackbar: false,
      errorMsg: "",
      headers: [
        {
          text: "Member ID ",
          align: "start",
          sortable: false,
          value: "memberNumber",
        },
        { text: "Category", value: "memberCategory" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "E-Mail", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Place of Work", value: "work" },
        { text: "", value: "view_action", sortable: false },
        { text: "", value: "edit_action", sortable: false },
        { text: "", value: "delete_action", sortable: false },
      ],
      categories: [
        "Foundation",
        "Technician",
        "Skill",
        "Advanced",
        "Professional",
        "Fellow",
      ],
      members: [],
      backupMembers: [],
      newMembers: "",
      newDocument: {},
      currentActiveMember: {},
      rules: {
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            (v && v.length >= 6) || "Password must be 6 characters or more!",
        ],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        name: [
          (value) =>
            !value ||
            value.size < 2000000 ||
            "Document size should be less than 2 MB!",
          (value) =>
            value.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            "Only Excel Documents Allowed!",
        ],
        document: [
          (value) =>
            !value ||
            value.size < 5000000 ||
            "Document size should be less than 5 MB!",
          (value) =>
            !value ||
            value.type === "application/pdf" ||
            "Only PDF Documents Allowed!",
        ],
      },
      json_fields: {
        "Member ID": "member",
        Category: "category",
        "First Name": "fname",
        "Last Name": "lname",
        "E-mail": "mail",
        Phone: "phone",
        "Place of Work": "work",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  computed: {
    formIsValid() {
      return this.newMembers !== "";
    },
    documentIsValid() {
      return (
        this.newDocument.name && this.newDocument.type && this.newDocument.file
      );
    },
  },
  created() {
    this.currentAdmin = JSON.parse(localStorage.getItem("user"));
    this.currentToken = localStorage.getItem("token");

    //fetch members
    this.fetchMembers();
  },
  methods: {
    fetchMembers() {
      //clear json data
      this.json_data = [];

      //get admins
      axios
        .get("member/all", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            search: this.search,
          },
        })
        .then((response) => {
          console.log(response.data);

          //loop through all results
          for (let i = 0; i < response.data.data.length; i++) {
            //get current member number
            let memberNumber = response.data.data[i].memberNumber;
            let memberCategory = response.data.data[i].memberCategory;
            let id = response.data.data[i].id;

            //get user with member number
            axios
              .get(`member/profile/${memberNumber}`, {
                headers: {
                  Authorization: `Bearer ${this.currentToken}`,
                },
              })
              .then((response) => {
                console.log(response.data.data);

                //get member data
                let member = response.data.data;

                //add object to members list
                this.members.push({
                  id: id,
                  memberNumber: memberNumber,
                  memberCategory: memberCategory,
                  firstName: member.firstName || "",
                  lastName: member.lastName || "",
                  email: member.email || "",
                  phone: member.phone || "",
                  work: member.placeOfWork || "",
                });

                //add object to backup members list
                this.backupMembers.push({
                  id: id,
                  memberNumber: memberNumber,
                  memberCategory: memberCategory,
                  firstName: member.firstName || "",
                  lastName: member.lastName || "",
                  email: member.email || "",
                  phone: member.phone || "",
                  work: member.placeOfWork || "",
                });

                //add object to excell sheet list
                this.json_data.push({
                  member: memberNumber,
                  category: memberCategory,
                  fname: member.firstName || "",
                  lname: member.lastName || "",
                  mail: member.email || "",
                  phone: member.phone || "",
                  work: member.placeOfWork || "",
                });
              })
              .catch((error) => {
                //add object to members list
                this.members.push({
                  id: id,
                  memberNumber: memberNumber,
                  memberCategory: memberCategory,
                  firstName: "",
                  lastName: "",
                  email: "",
                  phone: "",
                  work: "",
                });

                //add object to backup members list
                this.backupMembers.push({
                  id: id,
                  memberNumber: memberNumber,
                  memberCategory: memberCategory,
                  firstName: "",
                  lastName: "",
                  email: "",
                  phone: "",
                  work: "",
                });

                //add object to excell sheet list
                this.json_data.push({
                  member: memberNumber,
                  category: memberCategory,
                  fname: "",
                  lname: "",
                  mail: "",
                  phone: "",
                  work: "",
                });
              });
          }
        })
        .catch((error) => {});
    },
    filterMembers() {
      this.members = this.backupMembers;
      //check for search
      if (this.search.length > 0) {
        //check if search matches any data for each member

        this.members = this.members.filter((members) => {
          return (
            members.memberNumber.toLowerCase().includes(this.search) ||
            members.email.toLowerCase().includes(this.search.toLowerCase()) ||
            members.memberCategory
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            members.lastName.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      }
    },
    logFile(e) {
      console.log(e);
      //get file
      this.newMembers = e;
    },
    uploadMembers() {
      if (this.$refs.uploadForm.validate()) {
        //start loading
        this.uploading = true;

        //extract file
        const formData = new FormData();
        formData.append("file", this.newMembers);
        const headers = { "Content-Type": "multipart/form-data" };

        //api call
        axios
          .post("member/add", formData, {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.uploading = false;

            //show success
            this.successMsg = response.data.message;
            this.successSnackbar = true;

            //close dialog
            this.uploadDialog = false;

            //clear new admin form
            this.newMembers = "";

            //clear member list
            this.members = [];

            //reset form validation
            this.$refs.uploadForm.reset();

            //fetch members
            this.fetchMembers();
          })
          .catch((error) => {
            //stop loading
            this.uploading = false;

            //log error
            console.log(error.response.data);

            //display error message
            this.errorMsg = error.response.data.message;
            this.failureSnackbar = true;
          });
      } else {
        this.errorMsg = "File Invalid";
        this.failureSnackbar = true;
      }
    },
    validateEdit(member) {
      //set current member
      this.currentActiveMember = {
        id: member.id,
        memberNumber: member.memberNumber,
        memberCategory: member.memberCategory,
      };

      //open dialog
      this.editDialog = true;
    },
    editMember() {
      //start loading
      this.editing = true;

      //update member category
      axios
        .put(
          `member/update/${this.currentActiveMember.id}`,
          {
            memberCategory: this.currentActiveMember.memberCategory,
            memberNumber: this.currentActiveMember.memberNumber,
          },
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.editing = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //nullify current active member
          this.currentActiveMember = {};

          //close dialog
          this.editDialog = false;

          //clear list
          this.members = [];

          //fetch members
          this.fetchMembers();
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.editing = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    validateDelete(member) {
      //set current action admin
      this.currentActiveMember = member;

      //open dialog
      this.deleteDialog = true;
    },
    deleteMember() {
      //start loading
      this.deleting = true;

      //delete admin
      axios
        .delete(`member/delete/${this.currentActiveMember.memberNumber}`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.deleting = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //remove item from positions
          let index = this.members.findIndex(
            (item) => item.id == this.currentActiveMember.id
          );
          this.members.splice(index, 1);

          //nullify current active member
          this.currentActiveMember = {};

          //close dialog
          this.deleteDialog = false;
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.deleting = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    deleteItem(file) {
      console.log(this.currentActiveMember);
      console.log(file);
      //start loading
      this.deletingDocumentDialog = true;

      //delete admin
      axios
        .delete(
          `user/documents/delete?userId=${this.currentActiveMember.id}&docId=${file.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.deletingDocumentDialog = false;
          this.documentDialog = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //nullify current active member
          this.currentActiveMember = {};

          //close dialog
          this.viewDialog = false;
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.deletingDocumentDialog = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    validateView(member) {
      //start loading
      this.loadingDialog = true;

      //get user with member number
      axios
        .get(`member/profile/${member.memberNumber}`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data.data);

          //set data
          this.currentActiveMember = response.data.data;

          //stop loading
          this.loadingDialog = false;

          //show details dialog
          this.viewDialog = true;
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.loadingDialog = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    sortAvatar(avatar) {
      if (avatar == null || avatar == "") {
        return "https://firebasestorage.googleapis.com/v0/b/iifp-lms.appspot.com/o/Images%2Fuser_avatar.png?alt=media&token=9b53423e-3e9f-40d3-b6f0-b874a8f94ebb";
      } else {
        return avatar;
      }
    },
    validateDocumentDialog() {
      this.documentDialog = true;
    },
    downloadItem({ link, name }) {
      axios
        .get(link, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = document.createElement("a");
          url.href = URL.createObjectURL(blob);
          url.download = name;
          url.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    logDocumentFile(e) {
      console.log(e);
      //get file
      this.newDocument.file = e;
    },
    uploadDocument() {
      if (
        this.$refs.uploadDocumentForm.validate() &&
        this.newDocument.file != null
      ) {
        //start loading
        this.addingDocument = true;

        //build form
        const formData = new FormData();
        formData.append("type", this.newDocument.type);
        formData.append("name", this.newDocument.name);
        formData.append("file", this.newDocument.file);

        //api call
        axios
          .put(
            `user/add-document/${this.currentActiveMember.membership.memberNumber}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${this.currentToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.addingDocument = false;

            //show success
            this.successMsg = response.data.message;
            this.successSnackbar = true;

            //close dialog
            this.addDocumentDialog = false;

            //clear new admin form
            this.newDocument = {};

            //reset form validation
            this.$refs.uploadDocumentForm.reset();

            //fetch member data
            axios
              .get(
                `member/profile/${this.currentActiveMember.membership.memberNumber}`,
                {
                  headers: {
                    Authorization: `Bearer ${this.currentToken}`,
                  },
                }
              )
              .then((response) => {
                console.log(response.data.data);

                //set data
                this.currentActiveMember = response.data.data;
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            //stop loading
            this.addingDocument = false;

            //log error
            console.log(error.response.data);

            //display error message
            this.errorMsg = error.response.data.message;
            this.failureSnackbar = true;
          });
      } else {
        this.errorMsg = "Please add document to be uploaded";
        this.failureSnackbar = true;
      }
    },
    validateSubscriptionDialog() {
      //show subscription dialog
      this.subscriptionDialog = true;
    },
    validateSubscriptionDialog() {
      //show subscription dialog
      this.subscriptionDialog = true;
    },
    activateSubscription(member) {
      if (
        member.subscription.status === "Subscribed" ||
        member.subscription.status === "Trial"
      ) {
        //show error message
        this.errorMsg = "Member has active subscription!";
        this.failureSnackbar = true;
        return;
      }

      //start loading
      this.activatingSubscription = true;

      //activating subscription
      axios
        .put(
          `user/subscription/admin/renew`,
          {
            user: member.id,
          },
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.activatingSubscription = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //nullify current active member
          this.currentActiveMember = {};

          //close dialog
          this.subscriptionDialog = false;
          this.viewDialog = false;
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.activatingSubscription = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    sortStatusData(data) {
      if (data == null || data.status == null || data.status == "") {
        return "N/A";
      } else {
        return data.status;
      }
    },
    sortSubDateData(data) {
      if (
        data == null ||
        data.subscriptionDate == null ||
        data.subscriptionDate == ""
      ) {
        return "N/A";
      } else {
        return data.subscriptionDate;
      }
    },
    sortExpDateData(data) {
      if (data == null || data.expiryDate == null || data.expiryDate == "") {
        return "N/A";
      } else {
        return data.expiryDate;
      }
    },
    validateActivationDialog() {
      this.allActivationDialog = true;
    },
    activateSubscriptionForAll() {
      //start loading
      this.activatingAll = true;

      //activating subscription
      axios
        .put(
          `user/subscription/admin/renew/all`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.activatingAll = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //close dialog
          this.allActivationDialog = false;
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.activatingAll = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.position-instruction {
  color: #9e9e9e;
}

.new-position {
  display: inline-block;
  text-align: start;

  .the-chip {
    margin-right: 10px;
    display: inline-block;
  }
}

.edit-btn {
  color: #ffffff;
}

.create-btn {
  color: #ffffff;
}

.instruction {
  width: 100%;
  text-align: center;
  align-content: center;
  align-items: center;
}

.avatar {
  text-align: center;
  margin: 20px auto;
}

.extra-btn {
  text-align: center;

  img {
    width: 100%;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.5s ease;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
