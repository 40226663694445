<template>
  <div class="home">
    <v-subheader class="d-flex justify-space-between align-center">
      <h3>Positions</h3>
      <v-btn color="success" class="rounded-lg" @click="dialog = true">
        Add New
      </v-btn>
    </v-subheader>
    <v-row>
      <v-col lg="12">
        <v-data-table
          caption="Full Platform Logs"
          :headers="headers"
          :items="positions"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              :loading="deleting"
              class="delete-btn"
              color="red"
              @click="deletePosition(item.id)"
              >Delete</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="490">
        <v-card class="pa-10 text-center">
          <h2>Add New Positions</h2>
          <v-text-field
            class="mt-3"
            rounded
            outlined
            dense
            color="#106b42"
            placeholder="Enter name of postion . . ."
            v-model="position"
            label="Position Name"
            @keyup.enter="addPosition"
          />
          <p class="position-instruction">
            Press enter on your keyboard to add position to list.
          </p>
          <div
            class="new-position"
            v-for="(item, index) in newPositions"
            :key="index"
          >
            <v-chip
              class="ma-2 the-chip"
              close
              color="#106b42"
              outlined
              @click:close="removePosition(item)"
            >
              {{ item }}
            </v-chip>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog = false">
              Cancel
            </v-btn>
            <v-card-actions class="justify-center">
              <v-btn
                :loading="loading"
                type="submit"
                class="add-btn"
                color="#106b42"
                @click="uploadPositions"
              >
                <span class="white--text px-8">Add</span>
              </v-btn>
            </v-card-actions>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="1000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Positions",
  data() {
    return {
      currentAdmin: {},
      currentToken: "",
      loading: false,
      deleting: false,
      dialog: false,
      successSnackbar: false,
      successMsg: "",
      failureSnackbar: false,
      errorMsg: "",
      headers: [
        {
          text: "ID ",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Position", value: "name" },
        { text: "Action", value: "action" },
      ],
      position: "",
      positions: [],
      newPositions: [],
    };
  },
  created() {
    this.currentAdmin = JSON.parse(localStorage.getItem("user"));
    this.currentToken = localStorage.getItem("token");

    //fetch positions
    this.fetchPositions();
  },
  methods: {
    fetchPositions() {
      //get positions
      axios
        .get("admin/positions", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.positions = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    addPosition() {
      //check if position already exist in local list
      if (this.position.trim() == "") {
        this.errorMsg = "Empty Entry";
        this.failureSnackbar = true;
        this.position = "";
      } else if (this.newPositions.includes(this.position)) {
        this.errorMsg = "Added already";
        this.failureSnackbar = true;
        this.position = "";
      } else {
        this.newPositions.push(this.position);
        this.position = "";
      }
    },
    removePosition(position) {
      //remove position from local list
      this.newPositions.splice(this.newPositions.indexOf(position), 1);
    },
    uploadPositions() {
      //check if new positions are empty
      if (this.newPositions.length == 0) {
        this.errorMsg = "No new positions to add";
        this.failureSnackbar = true;
      } else {
        //start loading
        this.loading = true;

        //upload positions to server
        axios
          .post(
            "admin/position/add",
            {
              items: this.newPositions,
            },
            {
              headers: {
                Authorization: `Bearer ${this.currentToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data.message);

            //stop loading
            this.loading = false;

            //show success message
            this.successMsg = response.data.message;
            this.successSnackbar = true;

            //close dialog
            this.dialog = false;

            //clear positions
            this.positions = [];

            //fetch positions
            this.fetchPositions();
          })
          .catch((error) => {
            console.log(error);

            //stop loading
            this.loading = false;

            //show error message
            this.errorMsg = error.response.data.message;
            this.failureSnackbar = true;
          });
      }
    },
    deletePosition(id) {
      //start loading
      this.deleting = true;

      //delete position
      axios
        .delete(`admin/position/remove/${id}`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.deleting = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //remove item from positions
          let index = this.positions.findIndex((item) => item.id == id);
          this.positions.splice(index, 1);
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.deleting = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.position-instruction {
  color: #9e9e9e;
}

.new-position {
  display: inline-block;
  text-align: start;

  .the-chip {
    margin-right: 10px;
    display: inline-block;
  }
}

.delete-btn {
  color: #ffffff;
}
</style>
