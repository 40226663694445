<template>
  <div class="home">
    <div class="page-title">
      <h1>News Feed</h1>
    </div>

    <v-row>
      <v-col lg="12">
        <v-data-table
          caption=""
          :headers="headers"
          :items="news"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.newsApproved`]="{ item }">
            <v-switch
              v-model="item.newsApproved"
              inset
              color="#106b42"
              disabled
            ></v-switch>
          </template>

          <template v-slot:[`item.view_action`]="{ item }">
            <v-btn class="edit-btn" color="#2A7EF5" @click="validateView(item)"
              >View</v-btn
            >
          </template>

          <template v-slot:[`item.approve_action`]="{ item }">
            <v-btn
              class="edit-btn"
              color="#106b42"
              @click="validateApproval(item)"
              >Approve</v-btn
            >
          </template>

          <template v-slot:[`item.reject_action`]="{ item }">
            <v-btn class="edit-btn" color="red" @click="validateRejection(item)"
              >Reject</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="viewDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Feed Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="currentFeed.author"
                  :rules="rules.name"
                  disabled
                  outlined
                  label="Author"
                  required
                  hint="Original Poster"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-textarea
                  :rules="rules.name"
                  v-model="currentFeed.description"
                  disabled
                  outlined
                  name="input-7-4"
                  label="Feed caption"
                  hint=""
                  required
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
                v-for="(file, index) in currentFeed.files"
                :key="index"
              >
                <img class="media-list" :src="sortFile(file)" alt="" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="(viewDialog = false), (currentFeed = {})"
          >
            <b>CLOSE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="approveDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5" color="red">
          Approve News Feed?
        </v-card-title>

        <v-card-text>
          Are you sure you want to approve this news feed? Please note, it will
          be available to all members of IIFP.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red"
            text
            @click="(approveDialog = false), (currentFeed = {})"
          >
            <b>CANCEL</b>
          </v-btn>

          <v-btn :loading="approving" color="#106b42" text @click="approveFeed">
            <b>APPROVE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="rejectDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5" color="red">
          Reject News Feed?
        </v-card-title>

        <v-card-text>
          Are you sure you want to reject this news feed?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(rejectDialog = false), (currentFeed = {})"
          >
            <b>CANCEL</b>
          </v-btn>

          <v-btn :loading="rejecting" color="red" text @click="rejectFeed">
            <b>REJECT</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="1000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NewsFeed",
  data() {
    return {
      currentAdmin: {},
      currentToken: "",
      search: "",
      approving: false,
      editing: false,
      rejecting: false,
      approveDialog: false,
      viewDialog: false,
      rejectDialog: false,
      successSnackbar: false,
      successMsg: "",
      failureSnackbar: false,
      errorMsg: "",
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Brief", value: "description" },
        { text: "Date Added", value: "dateAdded", sortable: true },
        { text: "Author", value: "author", sortable: true },
        { text: "Status", value: "newsApproved", sortable: true },
        { text: "", value: "view_action", sortable: false },
        { text: "", value: "approve_action", sortable: false },
        { text: "", value: "reject_action", sortable: false },
      ],
      news: [],
      newFeed: {},
      newFeedFiles: [],
      currentFeed: {},
      rules: {
        name: [(val) => (val || "").length > 0 || "This field is required"],
        document: [
          (value) =>
            !value ||
            value.size < 15000000 ||
            "Document size should be less than 15 MB!",
          (value) =>
            value.type === "application/pdf" || "Only PDF Documents Allowed!",
        ],
      },
    };
  },
  computed: {
    entryIsValid() {
      return this.newFeed.description && this.newFeed.media;
    },
  },
  created() {
    this.currentAdmin = JSON.parse(localStorage.getItem("user"));
    this.currentToken = localStorage.getItem("token");

    //fetch news feed
    this.fetchNewsFeed();
  },
  methods: {
    fetchNewsFeed() {
      //get news feed
      axios
        .get("feed/unapproved", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            search: this.search,
          },
        })
        .then((response) => {
          console.log(response.data);

          //loop through all results
          for (let i = 0; i < response.data.data.length; i++) {
            //get news
            let feed = response.data.data[i];

            //get user with id
            axios
              .get(`user/get-profile/${feed.author}`, {
                headers: {
                  Authorization: `Bearer ${this.currentToken}`,
                },
              })
              .then((childResponse) => {
                console.log(childResponse.data.data);

                //get user data
                let user = childResponse.data.data;

                //add object to members list
                this.news.push({
                  id: feed.id,
                  description: feed.description,
                  newsApproved: feed.newsApproved,
                  dateAdded: feed.datePosted,
                  files: feed.media,
                  author: user.username,
                  email: user.email,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    filterFeeds() {
      //check for search
      if (this.search.length > 0) {
        //check if search matches any data for each member
        this.news = this.news.filter((feed) => {
          return feed.description.includes(this.search);
        });
      } else {
        //clear news feed
        this.news = [];

        //fetch news feed
        this.fetchNewsFeed();
      }
    },
    validateView(feed) {
      //set current paper
      this.currentFeed = feed;

      //open dialog
      this.viewDialog = true;
    },
    sortFile(file) {
      if (
        file != "" &&
        file != null &&
        file.fileType != "" &&
        file.fileType != null
      ) {
        if (
          file.fileType.includes("mp4") ||
          file.fileType.includes("MP4") ||
          file.fileType.includes("mkv")
        ) {
          return "https://firebasestorage.googleapis.com/v0/b/iifp-lms.appspot.com/o/Images%2Fvideo_placeholder.png?alt=media&token=2e165e95-a7c3-47c4-8532-feaa525b264d";
        } else {
          return file.fileLink;
        }
      }
    },
    validateApproval(feed) {
      //set entry
      this.currentFeed = feed;

      //open dialog
      this.approveDialog = true;
    },
    approveFeed() {
      //start loading
      this.approving = true;

      //api call
      axios
        .put(`feed/approve/${this.currentFeed.id}`, this.currentFeed, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);

          //stop loading
          this.approving = false;

          //show success
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //close dialog
          this.approveDialog = false;

          //clear new entry
          this.currentFeed = {};

          //clear feed
          this.news = [];

          //fetch news feed
          this.fetchNewsFeed();
        })
        .catch((error) => {
          //stop loading
          this.approving = false;

          //log error
          console.log(error.response.data);

          //display error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    validateRejection(feed) {
      //set current action paper
      this.currentFeed = feed;

      //open dialog
      this.rejectDialog = true;
    },
    rejectFeed() {
      //start loading
      this.rejecting = true;

      //api call
      axios
        .delete(`feed/reject/${this.currentFeed.id}`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);

          //stop loading
          this.rejecting = false;

          //show success
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //close dialog
          this.rejectDialog = false;

          //clear new entry
          this.currentFeed = {};

          //clear feed
          this.news = [];

          //fetch news feed
          this.fetchNewsFeed();
        })
        .catch((error) => {
          //stop loading
          this.rejecting = false;

          //log error
          console.log(error.response.data);

          //display error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.position-instruction {
  color: #9e9e9e;
}

.new-position {
  display: inline-block;
  text-align: start;

  .the-chip {
    margin-right: 10px;
    display: inline-block;
  }
}

.edit-btn {
  color: #ffffff;
}

.create-btn {
  color: #ffffff;
}

.instruction {
  width: 100%;
  text-align: center;
  align-content: center;
  align-items: center;
}

.avatar {
  text-align: center;
  margin: 20px auto;
}

.extra-btn {
  text-align: center;

  img {
    width: 100%;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.5s ease;

    &:hover {
      opacity: 1;
    }
  }
}

.media-list {
  width: 100%;
  height: auto;
}

.delete-icon {
  color: red;
  position: absolute;
  background: #ffffff;
  cursor: pointer;
}

.page-title {
  text-align: center;
  color: #106b42;
}
</style>
