<template>
  <v-navigation-drawer v-model="drawer" app>
    <v-img src="../assets/images/nav_bg.png" class="pa-4">
      <div class="text-center mt-4">
        <v-avatar class="mb-4" color="#eeeeee" size="64">
          <v-img aspect-ratio="30" src="../assets/images/logo.png"> </v-img>
        </v-avatar>
        <h2 class="title">IIFP Admin Panel</h2>
      </div>
    </v-img>

    <v-divider></v-divider>

    <v-list>
      <v-list-item
        v-for="[icon, text] in links"
        :key="icon"
        link
        @click="handleMenuEvent(text)"
      >
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Sidebar",
  props: ["drawer"],
  data() {
    return {
      cards: ["Today", "Yesterday"],
      links: [
        ["mdi-monitor-dashboard", "Dashboard"],
        ["mdi-shield-crown-outline", "Admins"],
        ["mdi-chevron-triple-up", "Positions"],
        ["mdi-account-group-outline", "Members"],
        ["mdi-file-document", "Materials"],
        ["mdi-newspaper-variant-outline", "News Feed"],
        ["mdi-archive-arrow-down-outline", "IIFP Archive"],
        ["mdi-store-search-outline", "Research"],
        ["mdi-advertisements", "Advertisements"],
        ["mdi-briefcase-account-outline", "Registry"],
      ],
    };
  },
  methods: {
    handleMenuEvent(title) {
      switch (title) {
        case "Dashboard":
          this.$router.replace("/dashboard/");
          break;
        case "Admins":
          this.$router.replace("/dashboard/admins");
          break;
        case "Positions":
          this.$router.replace("/dashboard/positions");
          break;
        case "Members":
          this.$router.replace("/dashboard/members");
          break;
        case "Materials":
          this.$router.replace("/dashboard/materials");
          break;
        case "News Feed":
          this.$router.replace("/dashboard/newsfeed");
          break;
        case "IIFP Archive":
          this.$router.replace("/dashboard/archive");
          break;
        case "Research":
          this.$router.replace("/dashboard/research");
          break;
        case "Advertisements":
          this.$router.replace("/dashboard/adverts");
          break;
        case "Registry":
          this.$router.replace("/dashboard/registry");
          break;
      }
    },
  },
};
</script>

<style>
.title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #106b42;
}
</style>
