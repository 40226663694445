<template>
  <div class="home">
    <v-subheader class="d-flex justify-space-between align-center">
      <v-col lg="4" cols="12">
        <v-form class="mt-5">
          <v-text-field
            rounded
            outlined
            dense
            color="#106b42"
            placeholder="Search research papers with names and authors"
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            @keyup.esc="search = ''"
            v-on:input="filterResearch"
          />
        </v-form>
      </v-col>
      <v-btn
        color="#106b42"
        class="rounded-lg create-btn"
        @click="createDialog = true"
      >
        ADD RESEARCH
      </v-btn>
    </v-subheader>

    <v-row>
      <v-col lg="12">
        <v-data-table
          caption="IIFP Research Papers"
          :headers="headers"
          :items="researchPapers"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.view_action`]="{ item }">
            <v-btn class="edit-btn" color="#2A7EF5" @click="validateView(item)"
              >View</v-btn
            >
          </template>

          <template v-slot:[`item.edit_action`]="{ item }">
            <v-btn class="edit-btn" color="#106b42" @click="validateEdit(item)"
              >Edit</v-btn
            >
          </template>

          <template v-slot:[`item.delete_action`]="{ item }">
            <v-btn class="edit-btn" color="red" @click="validateDelete(item)"
              >Delete</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="createDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add New Research Paper</span>
        </v-card-title>
        <v-form @submit.prevent="createResearchPaper" ref="createForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="newResearch.title"
                    :rules="rules.name"
                    outlined
                    label="Title*"
                    required
                    hint="Research Topic / Title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="newResearch.author"
                    outlined
                    :rules="rules.name"
                    label="Author*"
                    required
                    hint="Main Author of Paper"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="newResearch.publishYear"
                    type="number"
                    outlined
                    :rules="rules.name"
                    label="Year*"
                    required
                    hint="Year of Publication"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    :rules="rules.name"
                    outlined
                    name="input-7-4"
                    label="Research Brief*"
                    hint="Summary of Research"
                    required
                    v-model="newResearch.brief"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-file-input
                    @change="logFile($event)"
                    :rules="rules.document"
                    label="Select Research File"
                    outlined
                    dense
                    required
                    prepend-icon="mdi-folder"
                    accept="application/pdf"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(createDialog = false), (newResearch = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="creating"
              color="#106b42"
              text
              @click="createResearchPaper"
            >
              <b>CREATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Research Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="currentPaper.title"
                  :rules="rules.name"
                  disabled
                  label="Title"
                  required
                  hint="Research Topic"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentPaper.author"
                  disabled
                  :rules="rules.name"
                  label="Author"
                  required
                  hint="Main Author of Publication"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentPaper.publishYear"
                  disabled
                  :rules="rules.name"
                  label="Year"
                  required
                  hint="Year of Publication"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-textarea
                  :rules="rules.name"
                  disabled
                  name="input-7-4"
                  label="Campaign Description"
                  hint="Description of the product"
                  required
                  v-model="currentPaper.brief"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="(viewDialog = false), (currentPaper = {})"
          >
            <b>CLOSE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Research</span>
        </v-card-title>
        <v-form>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="currentPaper.title"
                    :rules="rules.name"
                    outlined
                    label="Title"
                    required
                    hint="Research Topic"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="currentPaper.author"
                    outlined
                    :rules="rules.name"
                    label="Author"
                    required
                    hint="Main Author of Publication"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="currentPaper.publishYear"
                    outlined
                    :rules="rules.name"
                    label="Year"
                    required
                    hint="Year of Publication"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    :rules="rules.name"
                    outlined
                    name="input-7-4"
                    label="Research Brief"
                    hint="Summary of the research"
                    required
                    v-model="currentPaper.brief"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(editDialog = false), (currentPaper = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="editing"
              color="#106b42"
              text
              @click="editResearch"
            >
              <b>UPDATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5" color="red">
          Remove Research?
        </v-card-title>

        <v-card-text>
          Are you sure you want to remove
          <strong>{{ currentPaper.title }}</strong> by
          {{ currentPaper.author }} from IIFP research pool.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteDialog = false), (currentPaper = {})"
          >
            <b>CANCEL</b>
          </v-btn>

          <v-btn :loading="deleting" color="red" text @click="deleteResearch">
            <b>DELETE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="3000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Research",
  data() {
    return {
      currentAdmin: {},
      currentToken: "",
      search: "",
      creating: false,
      editing: false,
      deleting: false,
      createDialog: false,
      viewDialog: false,
      editDialog: false,
      deleteDialog: false,
      successSnackbar: false,
      successMsg: "",
      failureSnackbar: false,
      errorMsg: "",
      headers: [
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        { text: "Author", value: "author" },
        { text: "Publish Year", value: "publishYear" },
        { text: "", value: "view_action", sortable: false },
        { text: "", value: "edit_action", sortable: false },
        { text: "", value: "delete_action", sortable: false },
      ],
      researchPapers: [],
      newResearch: {},
      currentPaper: {},
      rules: {
        name: [(val) => (val || "").length > 0 || "This field is required"],
        document: [
          (value) =>
            !value ||
            value.size < 30000000 ||
            "Document size should be less than 30 MB!",
          (value) =>
            !value ||
            value.type === "application/pdf" ||
            "Only PDF Documents Allowed!",
        ],
      },
    };
  },
  computed: {
    documentIsValid() {
      return (
        this.newResearch.title &&
        this.newResearch.author &&
        this.newResearch.publishYear &&
        this.newResearch.file &&
        this.newResearch.brief
      );
    },
  },
  created() {
    this.currentAdmin = JSON.parse(localStorage.getItem("user"));
    this.currentToken = localStorage.getItem("token");

    //fetch papers
    this.fetchPapers();
  },
  methods: {
    fetchPapers() {
      //get research papers
      axios
        .get("research/get", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            search: this.search,
          },
        })
        .then((response) => {
          console.log(response.data);

          //populate papers
          this.researchPapers = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    filterResearch() {
      //check for search
      if (this.search.length > 0) {
        //check if search matches any data for each member
        this.members = this.members.filter((members) => {
          return (
            members.memberNumber.includes(this.search) ||
            members.email.toLowerCase().includes(this.search.toLowerCase()) ||
            members.memberCategory
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            members.lastName.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      } else {
        //clear members
        this.members = [];

        //fetch members
        this.fetchMembers();
      }
    },
    logFile(e) {
      console.log(e);
      //get file
      this.newResearch.file = e;
    },
    createResearchPaper() {
      if (this.$refs.createForm.validate() && this.newResearch.file != null) {
        //start loading
        this.creating = true;

        //build form
        const formData = new FormData();
        formData.append("title", this.newResearch.title);
        formData.append("brief", this.newResearch.brief);
        formData.append("author", this.newResearch.author);
        formData.append("publishYear", this.newResearch.publishYear);
        formData.append("file", this.newResearch.file);

        //api call
        axios
          .post("research/create", formData, {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.creating = false;

            //show success
            this.successMsg = response.data.message;
            this.successSnackbar = true;

            //close dialog
            this.createDialog = false;

            //clear new admin form
            this.newResearch = {};

            //clear reserach list
            this.researchPapers = [];

            //reset form validation
            this.$refs.createForm.reset();

            //fetch research papers
            this.fetchPapers();
          })
          .catch((error) => {
            //stop loading
            this.creating = false;

            //log error
            console.log(error.response.data);

            //display error message
            this.errorMsg = error.response.data.message;
            this.failureSnackbar = true;
          });
      } else {
        this.errorMsg = "Please select file to upload!";
        this.failureSnackbar = true;
      }
    },
    validateView(research) {
      //set current paper
      this.currentPaper = research;

      //open dialog
      this.viewDialog = true;
    },
    validateEdit(research) {
      //set research
      this.currentPaper = research;

      //open dialog
      this.editDialog = true;
    },
    editResearch() {
      //start loading
      this.editing = true;

      //build form
      const formData = new FormData();
      formData.append("title", this.currentPaper.title);
      formData.append("brief", this.currentPaper.brief);
      formData.append("author", this.currentPaper.author);
      formData.append("publishYear", this.currentPaper.publishYear);

      //api call
      axios
        .put(`research/edit/${this.currentPaper.id}`, formData, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);

          //stop loading
          this.editing = false;

          //show success
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //close dialog
          this.editDialog = false;

          //clear data
          this.currentPaper = {};

          //clear reserach list
          this.researchPapers = [];

          //fetch research papers
          this.fetchPapers();
        })
        .catch((error) => {
          //stop loading
          this.editing = false;

          //log error
          console.log(error.response.data);

          //display error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    validateDelete(research) {
      //set current action paper
      this.currentPaper = research;

      //open dialog
      this.deleteDialog = true;
    },
    deleteResearch() {
      //start loading
      this.deleting = true;

      //delete admin
      axios
        .delete(`research/delete/${this.currentPaper.id}`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.deleting = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //remove item from positions
          let index = this.researchPapers.findIndex(
            (item) => item.id == this.currentPaper.id
          );
          this.researchPapers.splice(index, 1);

          //nullify current action admin
          this.currentPaper = {};

          //close dialog
          this.deleteDialog = false;
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.deleting = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.position-instruction {
  color: #9e9e9e;
}

.new-position {
  display: inline-block;
  text-align: start;

  .the-chip {
    margin-right: 10px;
    display: inline-block;
  }
}

.edit-btn {
  color: #ffffff;
}

.create-btn {
  color: #ffffff;
}

.instruction {
  width: 100%;
  text-align: center;
  align-content: center;
  align-items: center;
}

.avatar {
  text-align: center;
  margin: 20px auto;
}

.extra-btn {
  text-align: center;

  img {
    width: 100%;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.5s ease;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
