<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {},
  data: () => ({
    currentToken: "",
  }),
  computed: {
    ...mapGetters({
      user: "user",
      token: "token",
    }),
  },
  created() {
    //get current token
    this.currentToken = localStorage.getItem("token");

    //confirm token
    this.checkToken();

    //run check
    this.verifyToken();
  },
  methods: {
    checkToken() {
      //check if token is valid
      axios
        .get("admin/profile", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          if (response.data.data.active) {
            //update user
            localStorage.setItem("user", JSON.stringify(response.data.data));
          } else {
            //remove user and token
            localStorage.clear();

            //check route
            if (this.$route.name == "Dashboard") {
              this.$router.replace("/authenticate");
            }
          }
        })
        .catch((error) => {
          //remove user and token
          localStorage.clear();

          //check route
          if (this.$route.name == "Dashboard") {
            this.$router.replace("/authenticate");
          }
        });
    },
    verifyToken() {
      //verify token at interval
      setInterval(() => {
        if (this.currentToken != "" && this.currentToken != null) {
          this.checkToken();
        }
      }, 300000);
    },
  },
};
</script>
