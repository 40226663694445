import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./assets/css/spinkit.css";
import router from "./router";
import store from "./store";
import "./axios";
import OneSignalVue from "onesignal-vue";
import excel from "vue-excel-export";

Vue.config.productionTip = false;

//register global components
Vue.component("Header", require("./components/Header.vue").default);
Vue.component("Sidebar", require("./components/Sidebar.vue").default);

//use one signal
Vue.use(OneSignalVue);
Vue.use(excel);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  beforeMount() {
    this.$OneSignal.init({ appId: "1a78c173-14fd-42ca-a9cf-0359e9ddfdd8" });
  },
}).$mount("#app");
