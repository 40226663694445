<template>
  <div class="home">
    <v-subheader class="d-flex justify-space-between align-center">
      <v-col lg="4" cols="12">
        <v-form class="mt-5">
          <v-text-field
            rounded
            outlined
            dense
            color="#106b42"
            placeholder="Search archive"
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            @keyup.esc="search = ''"
            v-on:input="filterArchive"
          />
        </v-form>
      </v-col>
      <v-btn
        color="#106b42"
        class="rounded-lg create-btn"
        @click="createDialog = true"
      >
        ADD TO ARCHIVE
      </v-btn>
    </v-subheader>

    <v-row>
      <v-col lg="12">
        <v-data-table
          caption="IIFP Archive"
          :headers="headers"
          :items="archive"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.view_action`]="{ item }">
            <v-btn class="edit-btn" color="#2A7EF5" @click="validateView(item)"
              >View</v-btn
            >
          </template>

          <template v-slot:[`item.edit_action`]="{ item }">
            <v-btn class="edit-btn" color="#106b42" @click="validateEdit(item)"
              >Edit</v-btn
            >
          </template>

          <template v-slot:[`item.delete_action`]="{ item }">
            <v-btn class="edit-btn" color="red" @click="validateDelete(item)"
              >Delete</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="createDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add New Archive Entry</span>
        </v-card-title>
        <v-form @submit.prevent="createEntry" ref="createForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="newEntry.type"
                    outlined
                    required
                    :rules="rules.name"
                    :items="categories"
                    label="Select Category"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-file-input
                    @change="logFile($event)"
                    label="Select Files"
                    v-model="filesSelected"
                    required
                    outlined
                    multiple
                    truncate-length="15"
                    accept="image/png, image/jpeg, image/jpg, video/mp4, video/MP4, video/mkv"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    :rules="rules.name"
                    outlined
                    name="input-7-4"
                    label="Entry Brief*"
                    hint="Description of This Entry"
                    required
                    v-model="newEntry.description"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(createDialog = false), (newEntry = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="creating"
              color="#106b42"
              text
              @click="createEntry"
            >
              <b>CREATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Archive Entry Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="currentEntry.archiveType"
                  :rules="rules.name"
                  disabled
                  outlined
                  label="Title"
                  required
                  hint="Research Topic"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-textarea
                  :rules="rules.name"
                  v-model="currentEntry.description"
                  disabled
                  outlined
                  name="input-7-4"
                  label="Campaign Description"
                  hint="Description of the product"
                  required
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
                v-for="(file, index) in currentEntry.files"
                :key="index"
              >
                <img class="media-list" :src="sortFile(file)" alt="" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="(viewDialog = false), (currentEntry = {})"
          >
            <b>CLOSE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Archive Entry</span>
        </v-card-title>
        <v-form ref="editForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="currentEntry.archiveType"
                    :rules="rules.name"
                    disabled
                    outlined
                    label="Title"
                    required
                    hint="Research Topic"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    :rules="rules.name"
                    v-model="currentEntry.description"
                    disabled
                    outlined
                    name="input-7-4"
                    label="Campaign Description"
                    hint="Description of the product"
                    required
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-file-input
                    @change="logEditFile($event)"
                    label="Select Files You Want To Add"
                    required
                    v-model="filesSelected"
                    outlined
                    multiple
                    truncate-length="15"
                    accept="image/png, image/jpeg, image/jpg, video/mp4, video/MP4, video/mkv"
                  ></v-file-input>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  v-for="(file, index) in currentEntry.files"
                  :key="index"
                >
                  <!-- add remove icon -->

                  <img class="media-list" :src="sortFile(file)" alt="" />
                  <i
                    class="delete-icon mdi mdi-close-octagon"
                    @click="removeMediaFile(file)"
                  ></i>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(editDialog = false), (currentEntry = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn :loading="editing" color="#106b42" text @click="editEntry">
              <b>UPDATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5" color="red">
          Remove Archive Entry?
        </v-card-title>

        <v-card-text>
          Are you sure you want to remove this entry from IIFP Archives?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteDialog = false), (currentEntry = {})"
          >
            <b>CANCEL</b>
          </v-btn>

          <v-btn :loading="deleting" color="red" text @click="deleteArchive">
            <b>DELETE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="1000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Archive",
  data() {
    return {
      currentAdmin: {},
      currentToken: "",
      search: "",
      filesSelected: "",
      creating: false,
      editing: false,
      deleting: false,
      createDialog: false,
      viewDialog: false,
      editDialog: false,
      deleteDialog: false,
      successSnackbar: false,
      successMsg: "",
      failureSnackbar: false,
      errorMsg: "",
      headers: [
        {
          text: "Category",
          align: "start",
          value: "archiveType",
        },
        { text: "Brief", value: "description" },
        { text: "Date Added", value: "dateAdded" },
        { text: "", value: "view_action", sortable: false },
        { text: "", value: "edit_action", sortable: false },
        { text: "", value: "delete_action", sortable: false },
      ],
      categories: ["Events Gallery", "Training Videos"],
      archive: [],
      newEntry: {},
      newEntryFiles: [],
      currentEntry: {},
      rules: {
        name: [(val) => (val || "").length > 0 || "This field is required"],
        document: [
          (value) =>
            !value ||
            value.size < 30000000 ||
            "Document size should be less than 30 MB!",
          (value) =>
            !value ||
            value.type === "application/pdf" ||
            "Only PDF Documents Allowed!",
        ],
      },
    };
  },
  computed: {
    entryIsValid() {
      return (
        this.newEntry.type && this.newEntry.description && this.newEntry.media
      );
    },
  },
  created() {
    this.currentAdmin = JSON.parse(localStorage.getItem("user"));
    this.currentToken = localStorage.getItem("token");

    //fetch archive
    this.fetchArchive();
  },
  methods: {
    fetchArchive() {
      //get research papers
      axios
        .get("archive/get", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            search: this.search,
          },
        })
        .then((response) => {
          console.log(response.data);

          //populate archive
          this.archive = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    filterArchive() {
      //check for search
      if (this.search.length > 0) {
        //check if search matches any data for each member
        this.archive = this.archive.filter((entry) => {
          return entry.archiveType.includes(this.search);
        });
      } else {
        //clear archive
        this.archive = [];

        //fetch archive
        this.fetchArchive();
      }
    },
    logFile(e) {
      console.log(e);

      //check size
      for (let i = 0; i < e.length; i++) {
        //check for images
        if (
          e[i].name.endsWith(".jpg") ||
          e[i].name.endsWith(".jpeg") ||
          e[i].name.endsWith(".png") ||
          e[i].name.endsWith(".JPG")
        ) {
          if (e[i].size < 1024000) {
            //add to entry
            this.newEntry.media.push(e[i]);
          } else {
            //display error message
            this.errorMsg = "Cant upload images higher than 1mb in size.";
            this.failureSnackbar = true;
          }
        } else if (
          e[i].name.endsWith(".mp4") ||
          e[i].name.endsWith(".MP4") ||
          e[i].name.endsWith(".mkv")
        ) {
          if (e[i].size < 30024000) {
            //add to entry
            this.newEntry.media.push(e[i]);
          } else {
            //display error message
            this.errorMsg = "Cant upload videos higher than 30mb in size.";
            this.failureSnackbar = true;
          }
        }
      }
      this.filesSelected = this.newEntry.length + " Files Selected!";
    },
    logEditFile(e) {
      console.log(e);

      //check size
      for (let i = 0; i < e.length; i++) {
        //check for images
        if (
          e[i].name.endsWith(".jpg") ||
          e[i].name.endsWith(".jpeg") ||
          e[i].name.endsWith(".png") ||
          e[i].name.endsWith(".JPG")
        ) {
          if (e[i].size < 1024000) {
            //add to entry
            this.newEntry.media.push(e[i]);
          } else {
            //display error message
            this.errorMsg = "Cant upload images higher than 1mb in size.";
            this.failureSnackbar = true;
          }
        } else if (
          e[i].name.endsWith(".mp4") ||
          e[i].name.endsWith(".MP4") ||
          e[i].name.endsWith(".mkv")
        ) {
          if (e[i].size < 30024000) {
            //add to entry
            this.newEntry.media.push(e[i]);
          } else {
            //display error message
            this.errorMsg = "Cant upload videos higher than 30mb in size.";
            this.failureSnackbar = true;
          }
        }
      }

      this.filesSelected = this.newEntry.length + " Files Selected!";
    },
    createEntry() {
      if (this.$refs.createForm.validate() && this.newEntry.media != null) {
        //start loading
        this.creating = true;

        //build form
        const formData = new FormData();
        formData.append("type", this.newEntry.type);
        formData.append("description", this.newEntry.description);

        this.newEntry.media.forEach((element) => {
          formData.append("media", element);
        });

        console.log(formData);

        //api call
        axios
          .post("archive/create", formData, {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.creating = false;

            //show success
            this.successMsg = response.data.message;
            this.successSnackbar = true;

            //close dialog
            this.createDialog = false;

            //clear new entry
            this.newEntry = {};

            //clear archive list
            this.archive = [];

            //reset form validation
            this.$refs.createForm.reset();

            //fetch archive papers
            this.fetchArchive();
          })
          .catch((error) => {
            //stop loading
            this.creating = false;

            //log error
            console.log(error.response.data);

            //display error message
            this.errorMsg = error.response.data.message;
            this.failureSnackbar = true;
          });
      } else {
        this.errorMsg = "Please select files!";
        this.failureSnackbar = true;
      }
    },
    validateView(entry) {
      //set current paper
      this.currentEntry = entry;

      //open dialog
      this.viewDialog = true;
    },
    sortFile(file) {
      if (
        file != "" &&
        file != null &&
        file.fileType != "" &&
        file.fileType != null
      ) {
        if (
          file.fileType.includes("mp4") ||
          file.fileType.includes("MP4") ||
          file.fileType.includes("mkv")
        ) {
          return "https://firebasestorage.googleapis.com/v0/b/iifp-lms.appspot.com/o/Images%2Fvideo_placeholder.png?alt=media&token=2e165e95-a7c3-47c4-8532-feaa525b264d";
        } else {
          return file.fileUrl;
        }
      }
    },
    validateEdit(entry) {
      //set entry
      this.currentEntry = entry;

      //open dialog
      this.editDialog = true;
    },
    editEntry() {
      //start loading
      this.editing = true;

      //build form
      const formData = new FormData();
      formData.append("type", this.currentEntry.type);
      formData.append("description", this.currentEntry.description);

      //api call
      axios
        .put(`archive/edit/${this.currentEntry.id}`, formData, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);

          //check if files were added
          if (this.newEntryFiles.length > 0) {
            //build form
            const formData = new FormData();
            this.newEntryFiles.forEach((element) => {
              formData.append("media", element);
            });

            //api call
            axios
              .put(`archive/${this.currentEntry.id}/add-file`, formData, {
                headers: {
                  Authorization: `Bearer ${this.currentToken}`,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                console.log(response.data);

                //stop loading
                this.editing = false;

                //show success
                this.successMsg = response.data.message;
                this.successSnackbar = true;

                //close dialog
                this.editDialog = false;

                //clear new entry
                this.currentEntry = {};
                this.newEntryFiles = [];
                //reset form validation
                this.$refs.editForm.reset();

                //clear archive list
                this.archive = [];

                //fetch archive papers
                this.fetchArchive();
              })
              .catch((error) => {
                //stop loading
                this.editing = false;

                //log error
                console.log(error.response.data);

                //display error message
                this.errorMsg = error.response.data.message;
                this.failureSnackbar = true;
              });
          } else {
            //stop loading
            this.editing = false;

            //show success
            this.successMsg = response.data.message;
            this.successSnackbar = true;

            //close dialog
            this.editDialog = false;

            //clear new entry
            this.currentEntry = {};

            //clear archive list
            this.archive = [];

            //fetch archive papers
            this.fetchArchive();
          }
        })
        .catch((error) => {
          //stop loading
          this.editing = false;

          //log error
          console.log(error.response.data);

          //display error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    removeMediaFile(file) {
      //remove item from positions
      let index = this.currentEntry.files.findIndex(
        (item) => item.id == file.id
      );
      this.currentEntry.files.splice(index, 1);

      //delete file
      axios
        .delete(`archive/${this.currentEntry.id}/delete-file/`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            fileId: file.id,
          },
        })
        .then((response) => {
          console.log(response.data.message);
        })
        .catch((error) => {
          console.log(error);

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    validateDelete(entry) {
      //set current action paper
      this.currentEntry = entry;

      //open dialog
      this.deleteDialog = true;
    },
    deleteArchive() {
      //start loading
      this.deleting = true;

      //delete admin
      axios
        .delete(`archive/delete/${this.currentEntry.id}`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.deleting = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //remove item from positions
          let index = this.archive.findIndex(
            (item) => item.id == this.currentEntry.id
          );
          this.archive.splice(index, 1);

          //nullify current action admin
          this.currentEntry = {};

          //close dialog
          this.deleteDialog = false;
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.deleting = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.position-instruction {
  color: #9e9e9e;
}

.new-position {
  display: inline-block;
  text-align: start;

  .the-chip {
    margin-right: 10px;
    display: inline-block;
  }
}

.edit-btn {
  color: #ffffff;
}

.create-btn {
  color: #ffffff;
}

.instruction {
  width: 100%;
  text-align: center;
  align-content: center;
  align-items: center;
}

.avatar {
  text-align: center;
  margin: 20px auto;
}

.extra-btn {
  text-align: center;

  img {
    width: 100%;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.5s ease;

    &:hover {
      opacity: 1;
    }
  }
}

.media-list {
  width: 100%;
  height: auto;
}

.delete-icon {
  color: red;
  position: absolute;
  background: #ffffff;
  cursor: pointer;
}
</style>
