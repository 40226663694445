<template>
  <v-app id="inspire">
    <Header @handleDrawer="drawer = !drawer" />
    <Sidebar :drawer="drawer" />
    <v-container class="py-8 px-6" fluid>
      <router-view></router-view>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "Dashboard",

  data() {
    return {
      drawer: true,
    };
  },
  methods: {
    checkToken() {
      //check if token exists
      if (!localStorage.getItem("token")) {
        //go to login page
        this.$router.replace("/");
      }
    },
    logout() {
      //remove token from local storage
      localStorage.clear();

      //go home
      this.$router.replace("/");
    },
  },
  created() {
    this.checkToken();
  },
};
</script>
<style scoped>
.background {
  background: #106b42 !important;
  height: 100vh;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.loading {
  align-content: center;
  align-items: center;
  align-self: center;
  margin: auto;
  padding: 100px;
  border-bottom: 1px solid #eee;
}
</style>
