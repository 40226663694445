<template>
  <div class="home">
    <v-subheader class="d-flex justify-space-between align-center">
      <v-col lg="4" cols="12">
        <v-form class="mt-5">
          <v-text-field
            rounded
            outlined
            dense
            color="#106b42"
            placeholder="Search admins with name or username"
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            @keyup.esc="search = ''"
            v-on:input="filterAdmins"
          />
        </v-form>
      </v-col>
      <v-btn
        color="#106b42"
        class="rounded-lg create-btn"
        @click="validateCreate"
      >
        Create Admin
      </v-btn>
    </v-subheader>

    <v-row>
      <v-col lg="12">
        <v-data-table
          caption="IIFP Platform Administrators"
          :headers="headers"
          :items="admins"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              v-model="item.active"
              inset
              color="#106b42"
              disabled
            ></v-switch>
          </template>
          <template v-slot:[`item.edit_action`]="{ item }">
            <v-btn class="edit-btn" color="#106b42" @click="validateEdit(item)"
              >Edit</v-btn
            >
          </template>

          <template v-slot:[`item.delete_action`]="{ item }">
            <v-btn class="edit-btn" color="red" @click="validateDelete(item)"
              >Delete</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="createDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Create New IIFP Admin</span>
        </v-card-title>
        <v-form @submit.prevent="createAdmin" ref="createForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="newAdmin.firstName"
                    :rules="rules.name"
                    label="First Name*"
                    required
                    hint="Legal First Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="newAdmin.lastName"
                    :rules="rules.name"
                    label="Last Name*"
                    required
                    hint="Legal Last Name / Surname"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="newAdmin.username"
                    :rules="rules.name"
                    label="Username*"
                    type="text"
                    hint="Unique Username"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="newAdmin.email"
                    :rules="rules.email"
                    type="email"
                    label="Email*"
                    hint="Valid Email Address"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Password*"
                    :rules="rules.password"
                    type="password"
                    v-model="newAdmin.password"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-select
                    v-model="newAdmin.position"
                    :rules="rules.name"
                    :items="positions"
                    label="Select Position"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(createDialog = false), (newAdmin = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="creating"
              type="submit"
              color="#106b42"
              text
              @click="validateCreate"
              :disabled="!formIsValid"
            >
              <b>CREATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit {{ currentActionAdmin.username }}</span>
        </v-card-title>
        <v-form>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-select
                    v-model="currentActionAdmin.position"
                    :rules="rules.name"
                    :items="positions"
                    :label="currentActionAdmin.position"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-switch
                    v-model="currentActionAdmin.active"
                    inset
                    color="#106b42"
                    :label="currentActionAdmin.active ? 'Active' : 'Inactive'"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(editDialog = false), (currentActionAdmin = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn :loading="editing" color="#106b42" text @click="editAdmin">
              <b>UPDATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5" color="red"> Remove Admin? </v-card-title>

        <v-card-text>
          Are you sure you want to remove Admin (<strong>{{
            currentActionAdmin.username
          }}</strong
          >) from IIFP. <strong>{{ currentActionAdmin.username }}</strong> will
          no longer be able to access any of IIFP admin admin platforms
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteDialog = false), (currentActionAdmin = {})"
          >
            <b>CANCEL</b>
          </v-btn>

          <v-btn :loading="deleting" color="red" text @click="deleteAdmin">
            <b>DELETE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="1000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Admins",
  data() {
    return {
      currentAdmin: {},
      currentToken: "",
      search: "",
      currentActionAdmin: {},
      loading: false,
      creating: false,
      editing: false,
      deleting: false,
      createDialog: false,
      editDialog: false,
      deleteDialog: false,
      successSnackbar: false,
      successMsg: "",
      failureSnackbar: false,
      errorMsg: "",
      headers: [
        {
          text: "Username ",
          align: "start",
          sortable: false,
          value: "username",
        },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Position", value: "position" },
        { text: "E-Mail", value: "email" },
        { text: "Active", value: "active" },
        { text: "", value: "edit_action", sortable: false },
        { text: "", value: "delete_action", sortable: false },
      ],
      positions: [],
      admins: [],
      newAdmin: {},
      rules: {
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            (v && v.length >= 6) || "Password must be 6 characters or more!",
        ],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        name: [(val) => (val || "").length > 0 || "This field is required"],
      },
    };
  },
  computed: {
    formIsValid() {
      return (
        this.newAdmin.firstName &&
        this.newAdmin.lastName &&
        this.newAdmin.username &&
        this.newAdmin.position &&
        this.newAdmin.email &&
        this.newAdmin.password
      );
    },
  },
  created() {
    this.currentAdmin = JSON.parse(localStorage.getItem("user"));
    this.currentToken = localStorage.getItem("token");

    //fetch admins
    this.fetchAdmins();

    //fetch positions
    this.fetchPositions();
  },
  methods: {
    fetchAdmins() {
      //get admins
      axios
        .get("admin/search", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            search: this.search,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.admins = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    fetchPositions() {
      //get positions
      axios
        .get("admin/positions", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.positions = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    filterAdmins() {
      //check for search
      if (this.search.length > 0) {
        //check if search matches any data for each admin
        this.admins = this.admins.filter((admin) => {
          return (
            admin.username.toLowerCase().includes(this.search.toLowerCase()) ||
            admin.firstName.toLowerCase().includes(this.search.toLowerCase()) ||
            admin.lastName.toLowerCase().includes(this.search.toLowerCase()) ||
            admin.position.toLowerCase().includes(this.search.toLowerCase()) ||
            admin.email.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      } else {
        //clear admins
        this.admins = [];

        //fetch admins
        this.fetchAdmins();
      }
    },
    validateCreate() {
      if (this.currentAdmin.role === "SuperAdmin") {
        if (this.newAdmin.username === "") {
          this.errorMsg = "Username is required";
          this.failureSnackbar = true;
          return;
        }
        //open dialog
        this.createDialog = true;
      } else {
        //show error
        this.errorMsg = "You do not have permission to create admins";
        this.failureSnackbar = true;
      }
    },
    createAdmin() {
      if (this.$refs.createForm.validate()) {
        //start loading
        this.creating = true;

        //api call
        axios
          .post("admin/create", this.newAdmin, {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          })
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.creating = false;

            //show success
            this.successMsg = "Admin created successfully";
            this.successSnackbar = true;

            //close dialog
            this.createDialog = false;

            //clear new admin form
            this.newAdmin = {};

            //clear admin list
            this.admins = [];

            //reset form validation
            this.$refs.createForm.reset();

            //fetch admins
            this.fetchAdmins();
          })
          .catch((error) => {
            console.log(error.response.data);
            this.errorMsg = error.response.data.message;
            this.failureSnackbar = true;
          });
      } else {
        this.errorMsg = "Please check your fields";
        this.failureSnackbar = true;
      }
    },
    validateEdit(admin) {
      if (this.currentAdmin.role === "SuperAdmin") {
        if (this.currentAdmin.username === admin.username) {
          this.errorMsg = "SuperAdmin cannot be edited!";
          this.failureSnackbar = true;
          return;
        }

        //set current admin
        this.currentActionAdmin = {
          id: admin.id,
          username: admin.username,
          active: admin.active,
          position: admin.position,
        };

        //open dialog
        this.editDialog = true;
      } else {
        //show error
        this.errorMsg = "You do not have permission to edit admins";
        this.failureSnackbar = true;
      }
    },
    editAdmin() {
      //start loading
      this.editing = true;

      //update admin status
      axios
        .put(`admin/update-admin`, this.currentActionAdmin, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.editing = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //nullify current action admin
          this.currentActionAdmin = {};

          //close dialog
          this.editDialog = false;

          //clear list
          this.admins = [];

          //fetch admins
          this.fetchAdmins();
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.editing = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    validateDelete(admin) {
      if (this.currentAdmin.role === "SuperAdmin") {
        //check if admin is self
        if (admin.id === this.currentAdmin.id) {
          this.errorMsg = "You cannot delete yourself";
          this.failureSnackbar = true;
        } else {
          //set current action admin
          this.currentActionAdmin = admin;

          //open dialog
          this.deleteDialog = true;
        }
      } else {
        //show error
        this.errorMsg = "You do not have permission to delete admins";
        this.failureSnackbar = true;
      }
    },
    deleteAdmin() {
      //start loading
      this.deleting = true;

      //check if current user is target admin
      if (this.currentAdmin.id === this.currentActionAdmin.id) {
        this.errorMsg =
          "You cannot delete yourself. Please contact your administrator";
        this.failureSnackbar = true;
        this.deleting = false;
        return;
      }

      //delete admin
      axios
        .delete(`admin/delete-admin`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            id: this.currentActionAdmin.id,
          },
        })
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.deleting = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //remove item from positions
          let index = this.admins.findIndex(
            (item) => item.id == this.currentActionAdmin.id
          );
          this.admins.splice(index, 1);

          //nullify current action admin
          this.currentActionAdmin = {};

          //close dialog
          this.deleteDialog = false;
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.deleting = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.position-instruction {
  color: #9e9e9e;
}

.new-position {
  display: inline-block;
  text-align: start;

  .the-chip {
    margin-right: 10px;
    display: inline-block;
  }
}

.edit-btn {
  color: #ffffff;
}

.create-btn {
  color: #ffffff;
}
</style>
