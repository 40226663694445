<template>
  <div class="home">
    <v-subheader class="d-flex justify-space-between align-center">
      <v-col lg="4" cols="12">
        <v-form class="mt-5">
          <v-text-field
            rounded
            outlined
            dense
            color="#106b42"
            placeholder="Search registry with name"
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            @keyup.esc="search = ''"
            v-on:input="filterRegistry"
          />
        </v-form>
      </v-col>
      <v-btn
        color="#106b42"
        class="rounded-lg create-btn"
        @click="validateCreate"
      >
        Add Staff
      </v-btn>
    </v-subheader>

    <v-row>
      <v-col lg="12">
        <v-data-table
          caption="IIFP Staff Registry"
          :headers="headers"
          :items="registry"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.edit_action`]="{ item }">
            <v-btn class="edit-btn" color="#106b42" @click="validateEdit(item)"
              >Edit</v-btn
            >
          </template>

          <template v-slot:[`item.delete_action`]="{ item }">
            <v-btn class="edit-btn" color="red" @click="validateDelete(item)"
              >Delete</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="createDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Create New IIFP Staff</span>
        </v-card-title>
        <v-form @submit.prevent="createStaff" ref="createForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <div class="avatar">
                    <v-avatar size="120">
                      <input
                        type="file"
                        ref="file"
                        style="display: none"
                        @change="logFile($event)"
                        accept="image/jpeg, image/png"
                      />

                      <img
                        id="newAvatar"
                        :src="sortAvatar(imageToUploadB64)"
                        alt=""
                        @click="$refs.file.click()"
                      />
                    </v-avatar>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="newStaff.firstName"
                    :rules="rules.name"
                    label="First Name*"
                    required
                    hint="Legal First Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="newStaff.lastName"
                    :rules="rules.name"
                    label="Last Name*"
                    required
                    hint="Legal Last Name / Surname"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="newStaff.phone"
                    :rules="rules.name"
                    label="Phone*"
                    type="tel"
                    hint="Staff Phone Number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="newStaff.email"
                    :rules="rules.email"
                    type="email"
                    label="Email*"
                    hint="Valid Email Address"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="newStaff.position"
                    :rules="rules.name"
                    :items="positions"
                    label="Select Position"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="newStaff.category"
                    :rules="rules.name"
                    :items="categories"
                    label="Select Category"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(createDialog = false), (newStaff = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="creating"
              type="submit"
              color="#106b42"
              text
              @click="validateCreate"
            >
              <b>CREATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit IIFP Staff</span>
        </v-card-title>
        <v-form>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <div class="avatar">
                    <v-avatar size="120">
                      <input
                        type="file"
                        ref="file"
                        style="display: none"
                        @change="logFile($event)"
                        accept="image/jpeg, image/png"
                      />

                      <img
                        id="newAvatar"
                        :src="sortAvatar(imageToUploadB64)"
                        alt=""
                        @click="$refs.file.click()"
                      />
                    </v-avatar>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="currentActionStaff.firstName"
                    :rules="rules.name"
                    label="First Name*"
                    required
                    hint="Legal First Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="currentActionStaff.lastName"
                    :rules="rules.name"
                    label="Last Name*"
                    required
                    hint="Legal Last Name / Surname"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="currentActionStaff.phone"
                    :rules="rules.name"
                    label="Phone*"
                    type="tel"
                    hint="Staff Phone Number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="currentActionStaff.position"
                    :rules="rules.name"
                    :items="positions"
                    label="Select Position"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="currentActionStaff.category"
                    :rules="rules.name"
                    :items="categories"
                    label="Select Category"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(editDialog = false), (currentActionStaff = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn :loading="editing" color="#106b42" text @click="editStaff">
              <b>UPDATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" persistent max-width="350px">
      <v-card>
        <v-card-title class="text-h5" color="red"> Remove Staff? </v-card-title>

        <v-card-text>
          Are you sure you want to remove staff (<strong>{{
            currentActionStaff.firstName + " " + currentActionStaff.lastName
          }}</strong
          >) from IIFP Staff Registry.
          <strong>{{ currentActionStaff.firstName }}</strong> will no longer be
          available to members of the IIFP as a staff.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteDialog = false), (currentActionStaff = {})"
          >
            <b>CANCEL</b>
          </v-btn>

          <v-btn :loading="deleting" color="red" text @click="deleteStaff">
            <b>DELETE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="1000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Registry",
  data() {
    return {
      currentAdmin: {},
      currentToken: "",
      search: "",
      currentActionStaff: {},
      loading: false,
      creating: false,
      editing: false,
      deleting: false,
      createDialog: false,
      editDialog: false,
      deleteDialog: false,
      successSnackbar: false,
      successMsg: "",
      failureSnackbar: false,
      errorMsg: "",
      headers: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Position", value: "position" },
        { text: "Category", value: "category" },
        { text: "E-Mail", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "", value: "edit_action", sortable: false },
        { text: "", value: "delete_action", sortable: false },
      ],
      positions: [],
      categories: ["Governing Council", "Board of Advisers", "Management"],
      registry: [],
      newStaff: {},
      imageToUploadFile: null,
      imageToUploadB64: "",
      rules: {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        name: [(val) => (val || "").length > 0 || "This field is required"],
      },
    };
  },
  computed: {
    formIsValid() {
      return (
        this.newStaff.firstName &&
        this.newStaff.lastName &&
        this.newStaff.category &&
        this.newStaff.position &&
        this.newStaff.phone &&
        this.newStaff.email &&
        this.newStaff.avatar
      );
    },
  },
  created() {
    this.currentAdmin = JSON.parse(localStorage.getItem("user"));
    this.currentToken = localStorage.getItem("token");

    //fetch registry
    this.fetchRegistry();

    //fetch positions
    this.fetchPositions();
  },
  methods: {
    fetchRegistry() {
      //get admins
      axios
        .get("registry/staff-list", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            search: this.search,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.registry = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    fetchPositions() {
      //get positions
      axios
        .get("admin/positions", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.positions = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    filterRegistry() {
      //check for search
      if (this.search.length > 0) {
        //check if search matches any data for each staff
        this.registry = this.registry.filter((staff) => {
          return (
            staff.firstName.toLowerCase().includes(this.search.toLowerCase()) ||
            staff.lastName.toLowerCase().includes(this.search.toLowerCase()) ||
            staff.position.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      } else {
        //clear registry
        this.registry = [];

        //fetch registry
        this.fetchRegistry();
      }
    },
    sortAvatar(avatar) {
      if (avatar == null || avatar == "") {
        return "https://firebasestorage.googleapis.com/v0/b/iifp-lms.appspot.com/o/Images%2Fuser_avatar.png?alt=media&token=9b53423e-3e9f-40d3-b6f0-b874a8f94ebb";
      } else {
        return avatar;
      }
    },
    logFile(e) {
      console.log(e);
      let image = e.target.files[0];
      let form = new FormData();
      form.append("image", image);
      this.imageToUploadFile = image;
      this.newStaff.avatar = image;

      //convert to base 64
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        console.log(e.target.result);
        this.sortAvatar(e.target.result);
        this.imageToUploadB64 = e.target.result;
      };
    },
    validateCreate() {
      if (this.currentAdmin.role === "SuperAdmin") {
        //open dialog
        this.createDialog = true;
      } else {
        //show error
        this.errorMsg = "You do not have permission to create staff";
        this.failureSnackbar = true;
      }
    },
    createStaff() {
      if (this.$refs.createForm.validate() && this.newStaff.avatar != null) {
        //start loading
        this.creating = true;

        //extract file
        const formData = new FormData();
        formData.append("firstName", this.newStaff.firstName);
        formData.append("lastName", this.newStaff.lastName);
        formData.append("avatar", this.newStaff.avatar);
        formData.append("position", this.newStaff.position);
        formData.append("category", this.newStaff.category);
        formData.append("email", this.newStaff.email);
        formData.append("phone", this.newStaff.phone);

        //api call
        axios
          .post("registry/create", formData, {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.creating = false;

            //show success
            this.successMsg = "Staff created successfully";
            this.successSnackbar = true;

            //close dialog
            this.createDialog = false;

            //clear new admin form
            this.newStaff = {};
            this.imageToUploadFile = null;
            this.imageToUploadB64 = "";

            //clear admin list
            this.registry = [];

            //reset form validation
            this.$refs.createForm.reset();

            //fetch registry
            this.fetchRegistry();
          })
          .catch((error) => {
            //stop loading
            this.creating = false;

            console.log(error.response.data);
            this.errorMsg = error.response.data.message;
            this.failureSnackbar = true;
          });
      } else {
        this.errorMsg = "Please select an image for this staff";
        this.failureSnackbar = true;
      }
    },
    validateEdit(staff) {
      if (this.currentAdmin.role === "SuperAdmin") {
        //set current staff
        this.currentActionStaff = staff;
        this.imageToUploadB64 = staff.avatar;

        //open dialog
        this.editDialog = true;
      } else {
        //show error
        this.errorMsg = "You do not have permission to edit staff";
        this.failureSnackbar = true;
      }
    },
    editStaff() {
      //start loading
      this.editing = true;

      //create form
      const formData = new FormData();
      formData.append("firstName", this.currentActionStaff.firstName);
      formData.append("lastName", this.currentActionStaff.lastName);
      formData.append("position", this.currentActionStaff.position);
      formData.append("category", this.currentActionStaff.category);
      formData.append("phone", this.currentActionStaff.phone);

      //avatar
      if (this.imageToUploadFile != null) {
        formData.append("avatar", this.imageToUploadFile);
      }

      //update admin status
      axios
        .put(`registry/edit/${this.currentActionStaff.id}`, formData, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.editing = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //nullify current action admin
          this.currentActionStaff = {};
          this.newStaff = {};
          this.imageToUploadFile = null;
          this.imageToUploadB64 = "";

          //close dialog
          this.editDialog = false;

          //clear list
          this.registry = [];

          //fetch registry
          this.fetchRegistry();
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.editing = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    validateDelete(staff) {
      if (this.currentAdmin.role === "SuperAdmin") {
        //set current action admin
        this.currentActionStaff = staff;

        //open dialog
        this.deleteDialog = true;
      } else {
        //show error
        this.errorMsg = "You do not have permission to delete staff";
        this.failureSnackbar = true;
      }
    },
    deleteStaff() {
      //start loading
      this.deleting = true;

      //delete admin
      axios
        .delete(`registry/remove/${this.currentActionStaff.id}`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.deleting = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //remove item from positions
          let index = this.registry.findIndex(
            (item) => item.id == this.currentActionStaff.id
          );
          this.registry.splice(index, 1);

          //nullify current action admin
          this.currentActionStaff = {};

          //close dialog
          this.deleteDialog = false;
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.deleting = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.position-instruction {
  color: #9e9e9e;
}

.new-position {
  display: inline-block;
  text-align: start;

  .the-chip {
    margin-right: 10px;
    display: inline-block;
  }
}

.edit-btn {
  color: #ffffff;
}

.avatar {
  text-align: center;
  margin: 20px auto;
}

.create-btn {
  color: #ffffff;
}
</style>
