<template>
  <v-app>
    <div class="background"></div>
    <v-main class="d-flex justify-center align-center">
      <v-col cols="10" lg="4" class="mx-auto">
        <v-card class="pa-4">
          <div class="text-center">
            <v-avatar size="100" color="green lighten-5">
              <img
                class="login-img"
                src="../../assets/images/logo.png"
                alt="IIFP Logo"
              />
            </v-avatar>
            <h2 class="login-title">Reset Your Password</h2>
            <p class="recovery-instructions">
              Please provide your new password and confirm to successfully
              recover your password.
            </p>
          </div>
          <v-form @submit.prevent="submitHandler" ref="form">
            <v-card-text>
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="New Password"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Enter your new password"
                required
                prepend-inner-icon="mdi-key"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
              <v-text-field
                v-model="passwordConfirm"
                :rules="passwordRules"
                label="Confirm Password"
                :type="showPasswordConfirm ? 'text' : 'password'"
                placeholder="Confirm password entered above"
                required
                prepend-inner-icon="mdi-key"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPasswordConfirm = !showPasswordConfirm"
              />
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                :loading="loading"
                type="submit"
                class="login-btn"
                color="#106b42"
              >
                <span class="white--text px-8">Reset</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-main>
    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "ResetPassword",

  data: () => ({
    showPassword: false,
    showPasswordConfirm: false,
    loading: false,
    successSnackbar: false,
    successMsg: "",
    failureSnackbar: false,
    errorMsg: "",
    password: "",
    passwordConfirm: "",
    token: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 6) || "Password must be 6 characters or more!",
    ],
  }),
  created() {
    this.token = this.$route.query.token;
  },
  methods: {
    submitHandler() {
      //check if the form is valid
      if (
        this.$refs.form.validate() &&
        this.password === this.passwordConfirm
      ) {
        //start loading
        this.loading = true;

        //api call
        axios
          .post(
            "admin/reset-password",
            {
              newPassword: this.password,
            },
            {
              params: {
                token: this.token,
              },
            }
          )
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.loading = false;

            //show toast
            this.successSnackbar = true;
            this.successMsg = response.data.message;

            //go to login
            setTimeout(() => {
              this.$router.replace("/authenticate");
            }, 2000);
          })
          .catch((error) => {
            console.log(error.response.data);

            //stop loading
            this.loading = false;

            //show toast
            this.failureSnackbar = true;
            this.errorMsg = error.response.data.message;
          });
      } else {
        //show toast
        this.failureSnackbar = true;
        this.errorMsg = "Password confirm mismatch";
      }
    },
  },
};
</script>
<style scoped>
.background {
  background-image: url(../../assets/images/iifp_board.jpeg) !important;
  height: 100vh;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.login-img {
  width: 60%;
  height: auto;
}

.login-title {
  color: #106b42;
  margin-top: 20px;
}

.login-btn {
  color: #106b42;
}

.toast-msg {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.forgot-password {
  font-size: 16px;
  color: #106b42 !important;
  font-weight: bold;
  text-decoration: none;
}

.recovery-instructions {
  color: #515c51;
  margin-top: 5px;
  font-size: 12px;
}
</style>
