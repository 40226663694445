<template>
  <div class="home">
    <v-subheader class="d-flex justify-space-between align-center">
      <h3>Dashboard</h3>
    </v-subheader>
    <v-row>
      <v-col lg="7" cols="12">
        <v-alert dense text type="success">
          Welcome <strong>{{ currentAdmin.username }}</strong
          >,
        </v-alert>
        <v-row>
          <v-col
            lg="6"
            cols="12"
            v-for="(item, index) in statistics"
            :key="index"
          >
            <v-card elevation="2" class="rounded-lg">
              <v-card-text class="d-flex justify-space-between align-center">
                <div class="">
                  <strong>{{ item.title }}</strong
                  ><br />
                  <small>{{ item.desc }}</small>
                </div>
                <v-avatar :color="item.color" size="60">
                  <span class="white--text">{{ item.value }}</span>
                </v-avatar>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="5" cols="12">
        <v-card elevation="2" class="rounded-lg">
          <v-card-title>Logs</v-card-title>
          <v-timeline
            align-top
            dense
            v-for="(activity, index) in activities"
            :key="index"
          >
            <v-timeline-item color="#106b42" small>
              <strong>{{ activity.activity }}</strong>
              <div class="text-caption">
                @ {{ new Date(activity.timestamp) }}
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12">
        <v-data-table
          caption="Full Platform Logs"
          :headers="headers"
          :items="logs"
          :items-per-page="5"
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      currentAdmin: {},
      currentToken: "",
      statistics: [
        {
          title: "Total Admins",
          value: 0,
          color: "red",
          desc: "Total number of admins created for platform",
        },
        {
          title: "Registered Members",
          value: 0,
          color: "#106b42",
          desc: "All members registed and active",
        },
        {
          title: "News Feed",
          value: 0,
          color: "green",
          desc: "News feed on platform",
        },
        {
          title: "Research Papers",
          value: 0,
          color: "purple",
          desc: "Research papers uploaded on platform",
        },
        {
          title: "Training Videos",
          value: 0,
          color: "red",
          desc: "Training videos available on platform",
        },
        {
          title: "Registry",
          value: 0,
          color: "red",
          desc: "Total number of verified staff of IIFP",
        },
        {
          title: "Adverts",
          value: 0,
          color: "yellow",
          desc: "Active advertisments on platform",
        },
      ],
      headers: [
        {
          text: "Log ",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Admin", value: "admin" },
        { text: "Description", value: "activity" },
        { text: "Timestamp", value: "timestamp" },
      ],
      logs: [],
      activities: [],
    };
  },
  created() {
    this.currentAdmin = JSON.parse(localStorage.getItem("user"));
    this.currentToken = localStorage.getItem("token");

    //fetch logs
    this.fetchLogs();

    //fetch statistics
    this.fetchStatistics();
  },
  methods: {
    fetchLogs() {
      //get min logs
      axios
        .get("admin/logs", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            size: 5,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.activities = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });

      //get all logs
      axios
        .get("admin/logs", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            size: 0,
          },
        })
        .then((response) => {
          console.log(response.data);
          //this.logs = response.data.data;

          //loop through all result
          for (let i = 0; i < response.data.data.length; i++) {
            this.logs.push({
              id: response.data.data[i].id,
              admin: response.data.data[i].admin,
              activity: response.data.data[i].activity,
              timestamp: new Date(response.data.data[i].timestamp),
            });
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    fetchStatistics() {
      //admins
      axios
        .get("admin/search", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            searchQuery: "",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.statistics[0].value = response.data.data.length;
        })
        .catch((error) => {
          console.log(error.response.data);
        });

      //registered members
      axios
        .get("user/search", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            searchQuery: "",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.statistics[1].value = response.data.data.length;
        })
        .catch((error) => {
          console.log(error.response.data);
        });

      //news feed
      axios
        .get("feed/get", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.statistics[2].value = response.data.data.length;
        })
        .catch((error) => {
          console.log(error.response.data);
        });

      //research
      axios
        .get("research/get", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            searchQuery: "",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.statistics[3].value = response.data.data.length;
        })
        .catch((error) => {
          console.log(error.response.data);
        });

      //training video
      axios
        .get("archive/get", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            searchQuery: "Training Video",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.statistics[4].value = response.data.data.length;
        })
        .catch((error) => {
          console.log(error.response.data);
        });

      //registry
      axios
        .get("registry/staff-list", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            searchQuery: "",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.statistics[5].value = response.data.data.length;
        })
        .catch((error) => {
          console.log(error.response.data);
        });

      //adverts
      axios
        .get("advert/get", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.statistics[6].value = response.data.data.length;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style></style>
