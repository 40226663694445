<template>
  <div class="home">
    <div class="page-title">
      <h1>Academic Repository</h1>
    </div>

    <div class="title-indicator">
      <span class="helper"></span>
      <img
        src="../../assets/images/back_icon.svg"
        alt=""
        v-if="navigationStage != 'Home'"
        @click="backwardNavigation()"
      />
      <h2>Study Materials</h2>
      <i v-if="categoryHomeLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="categoryHomeLayout">{{ currentCategoryName }}</h2>
      <i v-if="courseHomeLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="courseHomeLayout">{{ currentCategoryName }}</h2>
      <i v-if="courseHomeLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="courseHomeLayout">{{ currentCourseData.title }}</h2>
      <i v-if="courseHomeLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="courseHomeLayout">Materials</h2>
      <i v-if="moduleHomeLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="moduleHomeLayout">{{ currentCategoryName }}</h2>
      <i v-if="moduleHomeLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="moduleHomeLayout">{{ currentCourseData.title }}</h2>
      <i v-if="moduleHomeLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="moduleHomeLayout">Materials</h2>
      <i v-if="moduleHomeLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="moduleHomeLayout">{{ currentModuleData.name }}</h2>
      <i v-if="moduleHomeLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="moduleHomeLayout">Files</h2>
      <i v-if="assessmentHomeLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="assessmentHomeLayout">{{ currentCategoryName }}</h2>
      <i v-if="assessmentHomeLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="assessmentHomeLayout">{{ currentCourseData.title }}</h2>
      <i v-if="assessmentHomeLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="assessmentHomeLayout">Assessment</h2>
      <i v-if="resultLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="resultLayout">{{ currentCategoryName }}</h2>
      <i v-if="resultLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="resultLayout">{{ currentCourseData.title }}</h2>
      <i v-if="resultLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="resultLayout">Assessment</h2>
      <i v-if="resultLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="resultLayout">{{ currentAssessment.label }}</h2>
      <i v-if="resultLayout" class="mdi mdi-chevron-right"></i>
      <h2 v-if="resultLayout">Results</h2>
    </div>

    <v-row class="category-list" v-if="categoryListLayout">
      <v-col lg="2" cols="12" v-for="(item, index) in categories" :key="index">
        <div class="category-img">
          <img
            :src="require(`../../assets/images/${item.image}`)"
            :alt="item.name"
            @click="
              item.name != 'Fellow'
                ? ((navigationStage = 'Courses'),
                  (currentCategoryName = item.name),
                  forwardNavigation(),
                  fetchCourses())
                : null
            "
          />
        </div>
      </v-col>
    </v-row>

    <v-subheader
      v-if="categoryHomeLayout"
      class="d-flex justify-space-between align-center"
    >
      <v-col lg="4" cols="12">
        <v-form class="mt-10">
          <v-text-field
            rounded
            outlined
            dense
            color="#106b42"
            placeholder="Search courses with course code"
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            @keyup.esc="search = ''"
            v-on:input="filterCourses"
          />
        </v-form>
      </v-col>
      <v-btn
        color="#106b42"
        class="rounded-lg create-btn"
        @click="addCourseDialog = true"
      >
        ADD COURSE
      </v-btn>
    </v-subheader>

    <v-row class="course-list" v-if="categoryHomeLayout">
      <v-col cols="3" v-for="(item, index) in courses" :key="index">
        <v-card color="#106b42" dark>
          <v-card-title class="text-h5"> {{ item.title }} </v-card-title>

          <v-card-subtitle>{{ item.description }}</v-card-subtitle>

          <v-card-actions>
            <v-btn text @click="validateMaterials(item)"> Materials </v-btn>
            <v-btn text @click="validateAssessment(item)"> Assessments </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn text @click="validateCourseDelete(item)"> Delete </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-subheader
      v-if="courseHomeLayout"
      class="d-flex justify-space-between align-center"
    >
      <v-col lg="4" cols="12">
        <v-form class="mt-10">
          <v-text-field
            rounded
            outlined
            dense
            color="#106b42"
            placeholder="Search modules with name"
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            @keyup.esc="search = ''"
            v-on:input="filterModules"
          />
        </v-form>
      </v-col>
      <v-btn
        color="#106b42"
        class="rounded-lg create-btn"
        @click="addModuleDialog = true"
      >
        ADD COURSE MODULE
      </v-btn>
    </v-subheader>

    <v-row class="course-list" v-if="courseHomeLayout">
      <v-col cols="3" v-for="(item, index) in modules" :key="index">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">{{ item.name }}</div>
              <v-list-item-title class="text-h5 mb-1">
                {{ item.coordinator }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ item.brief }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80" color="grey"
              ><v-img :src="item.thumb"></v-img
            ></v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              @click="
                (navigationStage = 'Files'),
                  (currentModuleData = item),
                  forwardNavigation(),
                  fetchModuleFiles()
              "
            >
              View Files
            </v-btn>

            <v-btn outlined rounded text @click="validateModuleDelete(item)">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-subheader
      v-if="moduleHomeLayout"
      class="d-flex justify-space-between align-center"
    >
      <v-col lg="4" cols="12">
        <v-form class="mt-10">
          <v-text-field
            rounded
            outlined
            dense
            color="#106b42"
            placeholder="Search chapters with name"
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            @keyup.esc="search = ''"
            v-on:input="filterModuleFiles"
          />
        </v-form>
      </v-col>
      <v-btn
        color="#106b42"
        class="rounded-lg create-btn"
        @click="addModuleFileDialog = true"
      >
        ADD MODULE CHAPTER
      </v-btn>
    </v-subheader>

    <v-row v-if="moduleHomeLayout">
      <v-col lg="12">
        <v-data-table
          caption=""
          :headers="fileHeaders"
          :items="moduleFiles"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.download_action`]="{ item }">
            <v-btn
              class="edit-btn"
              color="#106b42"
              @click="validateDownload(item)"
              >Download</v-btn
            >
          </template>

          <template v-slot:[`item.edit_action`]="{ item }">
            <v-btn class="edit-btn" color="#2A7EF5" @click="validateEdit(item)"
              >Edit</v-btn
            >
          </template>

          <template v-slot:[`item.delete_action`]="{ item }">
            <v-btn class="edit-btn" color="red" @click="validateDelete(item)"
              >Delete</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-subheader
      v-if="assessmentHomeLayout"
      class="d-flex justify-space-between align-center"
    >
      <v-col lg="4" cols="12">
        <v-form class="mt-10">
          <v-text-field
            rounded
            outlined
            dense
            color="#106b42"
            placeholder="Search assessments with label"
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            @keyup.esc="search = ''"
            v-on:input="filterAssessments"
          />
        </v-form>
      </v-col>
      <v-btn
        color="#106b42"
        class="rounded-lg create-btn"
        @click="addAssessmentDialog = true"
      >
        ADD COURSE ASSESSMENT
      </v-btn>
    </v-subheader>

    <v-row class="course-list" v-if="assessmentHomeLayout">
      <v-col cols="3" v-for="(item, index) in assessments" :key="index">
        <v-card class="mx-auto" max-width="344">
          <v-card-text>
            <p class="text-h4">{{ item.label }}</p>
            <p>{{ item.startDate }} - {{ item.endDate }}</p>
            <p>{{ item.questions.length }} Questions Set</p>
            <div class="text--primary">
              {{ item.instruction }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="blue"
              outlined
              rounded
              @click="validateEditAssessment(item)"
            >
              Edit
            </v-btn>
            <v-btn
              text
              color="red"
              outlined
              rounded
              @click="validateDeleteAssessment(item)"
            >
              Delete
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              text
              color="#106b42"
              outlined
              rounded
              @click="validateResults(item)"
            >
              View Results
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-subheader
      v-if="resultLayout"
      class="d-flex justify-space-between align-center"
    >
      <v-col lg="4" cols="12">
        <v-form class="mt-10">
          <v-text-field
            rounded
            outlined
            dense
            color="#106b42"
            placeholder="Search results with name, member number or email"
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            @keyup.esc="search = ''"
            v-on:input="filterResults"
          />
        </v-form>
      </v-col>
    </v-subheader>

    <v-row v-if="resultLayout">
      <v-col lg="12">
        <v-data-table
          caption=""
          :headers="resultHeaders"
          :items="results"
          :items-per-page="10"
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
      <v-btn color="#106b42" class="rounded-lg create-btn">
        <export-excel
          :data="json_data"
          :fields="json_fields"
          worksheet="Result Worksheet"
          name="result_export.xls"
        >
          Export To Excel
        </export-excel>
      </v-btn>
    </v-row>

    <v-dialog v-model="addCourseDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add New Course</span>
        </v-card-title>
        <v-form @submit.prevent="createCourse" ref="createForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="newCourse.title"
                    :rules="rules.name"
                    outlined
                    label="Course Code*"
                    required
                    hint="Course Code is the unique identifier for the course"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    :rules="rules.name"
                    outlined
                    name="input-7-4"
                    label="Course Title &amp; Description*"
                    hint="Course Title is the name of the course and a short description of the course"
                    required
                    v-model="newCourse.description"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(addCourseDialog = false), (newCourse = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="creating"
              color="#106b42"
              text
              @click="createCourse"
              :disabled="!courseIsValid"
            >
              <b>CREATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteCourseDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5" color="red">
          Remove Course?
        </v-card-title>

        <v-card-text>
          Are you sure you want to remove this course from IIFP repository? This
          is permanent.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteCourseDialog = false), (currentCourseData = {})"
          >
            <b>CANCEL</b>
          </v-btn>

          <v-btn
            :loading="deletingCourse"
            color="red"
            text
            @click="deleteCourse"
          >
            <b>DELETE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addModuleDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add New Course Module</span>
        </v-card-title>
        <v-form @submit.prevent="createCourseModule" ref="createForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="newModule.name"
                    :rules="rules.name"
                    outlined
                    label="Topic*"
                    required
                    hint="Name of this module"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="newModule.coordinator"
                    outlined
                    :rules="rules.name"
                    label="Coordinator*"
                    required
                    hint="The module is lead by this person"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    :rules="rules.name"
                    outlined
                    name="input-7-4"
                    label="Brief*"
                    hint="Module Introduction"
                    required
                    v-model="newModule.brief"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-file-input
                    @change="logModuleThumb($event)"
                    :rules="rules.document"
                    label="Select Module Thumbnail*"
                    outlined
                    dense
                    required
                    prepend-icon="mdi-folder"
                    accept="image/png, image/jpeg, image/jpg"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(addModuleDialog = false), (newModule = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="addingModule"
              color="#106b42"
              text
              @click="createCourseModule"
            >
              <b>CREATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteModuleDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5" color="red">
          Remove Module?
        </v-card-title>

        <v-card-text>
          Are you sure you want to remove this module from this course? This is
          permanent.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteModuleDialog = false), (currentModuleData = {})"
          >
            <b>CANCEL</b>
          </v-btn>

          <v-btn
            :loading="deletingModule"
            color="red"
            text
            @click="deleteModule"
          >
            <b>DELETE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addModuleFileDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add New Module File</span>
        </v-card-title>
        <v-form @submit.prevent="createCourseModuleFile" ref="createForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="newModuleFile.name"
                    :rules="rules.name"
                    outlined
                    label="Name*"
                    required
                    hint="File Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="newModuleFile.author"
                    outlined
                    :rules="rules.name"
                    label="Author*"
                    required
                    hint="Main Author of File"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-file-input
                    @change="logModuleFile($event)"
                    :rules="rules.file"
                    label="Select Lesson File"
                    outlined
                    dense
                    required
                    prepend-icon="mdi-folder"
                    accept="application/pdf"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(addModuleFileDialog = false), (newModuleFile = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="addingModuleFile"
              color="#106b42"
              text
              @click="createCourseModuleFile"
            >
              <b>CREATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editModuleFileDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Module File</span>
        </v-card-title>
        <v-form ref="createForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="currentModuleFileData.name"
                    :rules="rules.name"
                    outlined
                    label="Name"
                    hint="File Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="currentModuleFileData.author"
                    outlined
                    :rules="rules.name"
                    label="Author"
                    hint="Main Author of File"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-file-input
                    @change="logEditModuleFile($event)"
                    :rules="rules.file"
                    label="Select Lesson File"
                    outlined
                    dense
                    prepend-icon="mdi-folder"
                    accept="application/pdf"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="
                (editModuleFileDialog = false), (currentModuleFileData = {})
              "
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="editingFile"
              color="#106b42"
              text
              @click="editModuleFile"
            >
              <b>UPDATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addAssessmentDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add New Assessment</span>
        </v-card-title>
        <v-form @submit.prevent="createAssessment" ref="createForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="newAssessment.label"
                    :rules="rules.name"
                    outlined
                    label="Label*"
                    required
                    hint="Assessment Name / Label (e.g 2018/2019 Semester 1)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    :rules="rules.name"
                    outlined
                    name="input-7-4"
                    label="Instructions for assessment*"
                    hint="Instructions for this paper. Please make as clear as possible. "
                    required
                    v-model="newAssessment.instructions"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="newAssessment.questionsPerHead"
                    outlined
                    type="number"
                    :rules="rules.number"
                    label="Question Quantity*"
                    required
                    hint="Amount of questions to be set for students"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div>
                    <v-menu
                      ref="startMenu"
                      v-model="startMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="newAssessment.startDate"
                          label="Start Date"
                          readonly
                          outlined
                          required
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newAssessment.startDate"
                        :active-picker.sync="activePicker"
                        :min="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        @change="saveStartDate"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div>
                    <v-menu
                      ref="endMenu"
                      v-model="endMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="newAssessment.endDate"
                          label="End Date"
                          readonly
                          outlined
                          required
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newAssessment.endDate"
                        :active-picker.sync="activePicker"
                        :min="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        @change="saveEndDate"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="newAssessment.duration"
                    outlined
                    type="number"
                    :rules="rules.number"
                    label="Duration (Mins)*"
                    required
                    hint="Time for exam in minutes"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="newAssessment.startTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="newAssessment.startTime"
                        label="Start Time"
                        readonly
                        outlined
                        required
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      v-model="newAssessment.startTime"
                      full-width
                      @click:minute="$refs.menu.save(newAssessment.startTime)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4">
                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="newAssessment.endTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="newAssessment.endTime"
                        label="End Time"
                        outlined
                        required
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="modal2"
                      v-model="newAssessment.endTime"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(newAssessment.endTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-file-input
                    @change="logAssessmentFile($event)"
                    :rules="rules.assessment"
                    label="Select Question File"
                    outlined
                    dense
                    required
                    prepend-icon="mdi-folder"
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(addAssessmentDialog = false), (newAssessment = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="addingAssessment"
              color="#106b42"
              text
              @click="createAssessment"
            >
              <b>CREATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="#106b42" dark>
        <v-card-text>
          Loading, please stand by . . .
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteFileDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5" color="red">
          Remove Module File?
        </v-card-title>

        <v-card-text>
          Are you sure you want to remove
          <strong>{{ currentModuleFileData.name }}</strong> by
          {{ currentModuleFileData.author }} from this module?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteFileDialog = false), (currentModuleFileData = {})"
          >
            <b>CANCEL</b>
          </v-btn>

          <v-btn :loading="deletingFile" color="red" text @click="deleteFile">
            <b>DELETE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteAssessmentDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5" color="red">
          Delete Assessment?
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete
          <strong>{{ currentAssessment.label }}</strong> assessment?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteAssessmentDialog = false), (currentAssessment = {})"
          >
            <b>CANCEL</b>
          </v-btn>

          <v-btn
            :loading="deletingAssessment"
            color="red"
            text
            @click="deleteAssessment"
          >
            <b>DELETE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editAssessmentDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Assessment</span>
        </v-card-title>
        <v-form ref="createForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="currentAssessment.label"
                    :rules="rules.name"
                    outlined
                    label="Label"
                    hint="Assessment Name / Label (e.g 2018/2019 Semester 1)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    :rules="rules.name"
                    outlined
                    name="input-7-4"
                    label="Instructions for assessment"
                    hint="Instructions for this paper. Please make as clear as possible. "
                    v-model="currentAssessment.instruction"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="currentAssessment.questionsPerHead"
                    outlined
                    type="number"
                    label="Question Quantity"
                    hint="Amount of questions to be set for students"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div>
                    <v-menu
                      ref="startMenu"
                      v-model="startMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="currentAssessment.startDate"
                          label="Start Date"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="currentAssessment.startDate"
                        :active-picker.sync="activePicker"
                        :min="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        @change="saveStartDate"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div>
                    <v-menu
                      ref="endMenu"
                      v-model="endMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="currentAssessment.endDate"
                          label="End Date"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="currentAssessment.endDate"
                        :active-picker.sync="activePicker"
                        :min="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        @change="saveEndDate"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="currentAssessment.duration"
                    outlined
                    type="number"
                    label="Duration (Mins)"
                    hint="Time for exam in minutes"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="currentAssessment.startTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="currentAssessment.startTime"
                        label="Start Time"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      v-model="currentAssessment.startTime"
                      full-width
                      @click:minute="
                        $refs.menu.save(currentAssessment.startTime)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4">
                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="currentAssessment.endTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="currentAssessment.endTime"
                        label="End Time"
                        outlined
                        required
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="modal2"
                      v-model="currentAssessment.endTime"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(currentAssessment.endTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(editAssessmentDialog = false), (currentAssessment = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="editingAssessment"
              color="#106b42"
              text
              @click="updateAssessment"
            >
              <b>UPDATE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="1000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Materials",
  data() {
    return {
      currentAdmin: {},
      currentToken: "",
      loadingDialog: false,
      categoryHomeInd: false,
      courseHomeInd: false,
      moduleHomeInd: false,
      assessmentHomeInd: false,
      categoryListLayout: true,
      categoryHomeLayout: false,
      courseHomeLayout: false,
      moduleHomeLayout: false,
      assessmentHomeLayout: false,
      currentCategoryName: "",
      currentCourseName: "",
      currentModuleName: "",
      search: "",
      addCourseDialog: false,
      creating: false,
      newCourse: {},
      currentCourseData: {},
      addModuleDialog: false,
      addingModule: false,
      newModule: {},
      currentModuleData: {},
      addModuleFileDialog: false,
      addingModuleFile: false,
      newModuleFile: {},
      currentModuleFileData: {},
      deleteCourseDialog: false,
      deleteModuleDialog: false,
      deleteFileDialog: false,
      deletingCourse: false,
      deletingModule: false,
      deletingFile: false,
      addAssessmentDialog: false,
      newAssessment: {},
      currentAssessmentData: {},
      addingAssessment: false,
      editModuleFileDialog: false,
      editingFile: false,
      editAssessmentDialog: false,
      editingAssessment: false,
      deleteAssessmentDialog: false,
      deletingAssessment: false,
      resultLayout: false,
      resultInd: false,
      currentAssessment: {},
      currentAssessmentName: "",
      successSnackbar: false,
      successMsg: "",
      failureSnackbar: false,
      errorMsg: "",
      categories: [
        {
          name: "Foundation",
          image: "foundation_img.png",
        },
        {
          name: "Technician",
          image: "technician_img.png",
        },
        {
          name: "Skill",
          image: "skill_img.png",
        },
        {
          name: "Advanced",
          image: "advanced_img.png",
        },
        {
          name: "Professional",
          image: "professionals_img.png",
        },
        {
          name: "Fellow",
          image: "fellow_img.png",
        },
      ],
      courses: [],
      modules: [],
      moduleFiles: [],
      assessments: [],
      results: [],
      fileHeaders: [
        { text: "Name", value: "name", sortable: true },
        { text: "Author", value: "author", sortable: true },
        { text: "", value: "download_action", sortable: false },
        { text: "", value: "edit_action", sortable: false },
        { text: "", value: "delete_action", sortable: false },
      ],
      resultHeaders: [
        { text: "Member ID", value: "memberNumber", sortable: true },
        { text: "First Name", value: "firstName", sortable: true },
        { text: "Last Name", value: "lastName", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Score", value: "score", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Q Answered", value: "answered", sortable: true },
        { text: "Submitted On", value: "submitted", sortable: true },
      ],
      rules: {
        name: [(val) => (val || "").length > 0 || "This field is required"],
        number: [
          (val) =>
            (val || "").length > 0 ||
            isNaN(val) ||
            "This field is required to be a number",
        ],
        document: [
          (value) =>
            !value ||
            value.size < 5000000 ||
            "Image size should be less than 5 MB!",
          (value) =>
            !value ||
            value.type === "image/png" ||
            value.type === "image/jpeg" ||
            value.type === "image/jpg" ||
            "Only Image Allowed!",
        ],
        file: [
          (value) =>
            !value ||
            value.size < 30000000 ||
            "Image size should be less than 30 MB!",
          (value) =>
            !value ||
            value.type === "application/pdf" ||
            "Only PDF Files Allowed!",
        ],
        assessment: [
          (value) =>
            !value ||
            value.size < 30000000 ||
            "Image size should be less than 30 MB!",
          (value) =>
            !value ||
            value.type === "application/vnd.ms-excel" ||
            value.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            "Only Excel Files Allowed!",
        ],
      },
      activePicker: null,
      startDate: null,
      endDate: null,
      startMenu: false,
      endMenu: false,
      time: null,
      menu2: false,
      modal2: false,
      navigationStage: "Home",
      json_fields: {
        "Member ID": "member",
        "First Name": "fname",
        "Last Name": "lname",
        "E-mail": "mail",
        Score: "score",
        Status: "status",
        "Q. Answered": "answered",
        "Date Submitted": "submitted",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  computed: {
    courseIsValid() {
      return this.newCourse.title && this.newCourse.description;
    },
    moduleIsValid() {
      return (
        this.newModule.name &&
        this.newModule.brief &&
        this.newModule.coordinator &&
        this.newModule.thumb
      );
    },
    fileIsValid() {
      return (
        this.newModuleFile.name &&
        this.newModuleFile.author &&
        this.newModuleFile.file
      );
    },
    assessmentIsValid() {
      return (
        this.newAssessment.label &&
        this.newAssessment.instructions &&
        this.newAssessment.questionsPerHead &&
        this.newAssessment.startDate &&
        this.newAssessment.endDate &&
        this.newAssessment.startTime &&
        this.newAssessment.endTime &&
        this.newAssessment.file
      );
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  created() {
    this.currentAdmin = JSON.parse(localStorage.getItem("user"));
    this.currentToken = localStorage.getItem("token");
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    fetchCourses() {
      //get all courses
      axios
        .get("course/all-courses", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
          params: {
            searchQuery: this.currentCategoryName,
          },
        })
        .then((response) => {
          console.log(response.data);

          this.courses = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    filterCourses() {
      //check for search
      if (this.search.length > 0) {
        //check if search matches any data for each course
        this.courses = this.courses.filter((course) => {
          return course.title.includes(this.search);
        });
      } else {
        //clear course list
        this.courses = [];

        //fetch course
        this.fetchCourses();
      }
    },
    createCourse() {
      if (this.$refs.createForm.validate()) {
        //start loading
        this.creating = true;

        //api call
        axios
          .post(
            "course/create",
            {
              title: this.newCourse.title,
              description: this.newCourse.description,
              category: this.currentCategoryName,
            },
            {
              headers: {
                Authorization: `Bearer ${this.currentToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.creating = false;

            //show success
            this.successMsg = "Course added successfully";
            this.successSnackbar = true;

            //close dialog
            this.addCourseDialog = false;

            //clear temp course data
            this.newCourse = {};

            //clear courses
            this.courses = [];

            //reset form validation
            this.$refs.createForm.reset();

            //fetch courses
            this.fetchCourses();
          })
          .catch((error) => {
            console.log(error.response.data);
            this.errorMsg = error.response.data.message;
            this.failureSnackbar = true;
          });
      } else {
        this.errorMsg = "Please check your fields";
        this.failureSnackbar = true;
      }
    },
    validateMaterials(course) {
      //course
      this.currentCourseData = course;

      //navigation
      this.navigationStage = "Modules";
      this.forwardNavigation();

      //fetch materials
      this.fetchModules();
    },
    validateCourseDelete(course) {
      //set entry
      this.currentCourseData = course;

      //open dialog
      this.deleteCourseDialog = true;
    },
    deleteCourse() {
      //start loading
      this.deletingCourse = true;

      //api call
      axios
        .delete(`course/delete/${this.currentCourseData.id}`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);

          //stop loading
          this.deletingCourse = false;

          //show success
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //close dialog
          this.deleteCourseDialog = false;

          //clear current entry
          this.currentCourseData = {};

          //clear files
          this.courses = [];

          //fetch module files
          this.fetchCourses();
        })
        .catch((error) => {
          //stop loading
          this.deletingCourse = false;

          //log error
          console.log(error.response.data);

          //display error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    fetchModules() {
      //get all courses
      axios
        .get(`course/${this.currentCourseData.id}/modules`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);

          this.modules = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    filterModules() {
      //check for search
      if (this.search.length > 0) {
        //check if search matches any data for each course
        this.modules = this.modules.filter((module) => {
          return module.name.includes(this.search);
        });
      } else {
        //clear module list
        this.modules = [];

        //fetch modules
        this.fetchModules();
      }
    },
    sortFile(file) {
      if (
        file != "" &&
        file != null &&
        file.fileType != "" &&
        file.fileType != null
      ) {
        if (
          file.fileType.includes("mp4") ||
          file.fileType.includes("MP4") ||
          file.fileType.includes("mkv")
        ) {
          return "https://firebasestorage.googleapis.com/v0/b/iifp-lms.appspot.com/o/Images%2Fvideo_placeholder.png?alt=media&token=2e165e95-a7c3-47c4-8532-feaa525b264d";
        } else {
          return file.fileLink;
        }
      }
    },
    logModuleThumb(e) {
      console.log(e);

      //init media
      this.newModule.thumb = e;
    },
    createCourseModule() {
      if (this.$refs.createForm.validate() && this.newModule.thumb != null) {
        //start loading
        this.addingModule = true;

        //build form
        const formData = new FormData();
        formData.append("name", this.newModule.name);
        formData.append("brief", this.newModule.brief);
        formData.append("coordinator", this.newModule.coordinator);
        formData.append("thumb", this.newModule.thumb);

        //api call
        axios
          .post(`course/${this.currentCourseData.id}/add-module`, formData, {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          })
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.addingModule = false;

            //show success
            this.successMsg = "Course module added successfully";
            this.successSnackbar = true;

            //close dialog
            this.addModuleDialog = false;

            //clear temp course data
            this.newModule = {};

            //clear courses
            this.modules = [];

            //reset form validation
            this.$refs.createForm.reset();

            //fetch modules
            this.fetchModules();
          })
          .catch((error) => {
            console.log(error.response.data);
            this.errorMsg = error.response.data.message;
            this.failureSnackbar = true;
          });
      } else {
        this.errorMsg = "Please choose a course thumbnail";
        this.failureSnackbar = true;
      }
    },
    validateModuleDelete(courseModule) {
      //set entry
      this.currentModuleData = courseModule;

      //open dialog
      this.deleteModuleDialog = true;
    },
    deleteModule() {
      //start loading
      this.deletingModule = true;

      //api call
      axios
        .delete(`course/delete-module/${this.currentModuleData.id}`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);

          //stop loading
          this.deletingModule = false;

          //show success
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //close dialog
          this.deleteModuleDialog = false;

          //clear current entry
          this.currentModuleData = {};

          //clear files
          this.modules = [];

          //fetch module files
          this.fetchModules();
        })
        .catch((error) => {
          //stop loading
          this.deletingModule = false;

          //log error
          console.log(error.response.data);

          //display error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    logModuleFile(e) {
      console.log(e);

      //init media
      this.newModuleFile.file = e;
    },
    createCourseModuleFile() {
      if (this.$refs.createForm.validate() && this.newModuleFile.file != null) {
        //start loading
        this.addingModuleFile = true;

        //build form
        const formData = new FormData();
        formData.append("name", this.newModuleFile.name);
        formData.append("author", this.newModuleFile.author);
        formData.append("file", this.newModuleFile.file);

        //api call
        axios
          .post(`course/${this.currentModuleData.id}/add-file`, formData, {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          })
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.addingModuleFile = false;

            //show success
            this.successMsg = "Course module file added successfully";
            this.successSnackbar = true;

            //close dialog
            this.addModuleFileDialog = false;

            //clear temp course data
            this.newModuleFile = {};

            //clear files
            this.moduleFiles = [];

            //reset form validation
            this.$refs.createForm.reset();

            //fetch modules
            this.fetchModuleFiles();
          })
          .catch((error) => {
            //stop loading
            this.addingModuleFile = false;

            console.log(error.response.data);
            this.errorMsg = error.response.data.message;
            this.failureSnackbar = true;
          });
      } else {
        this.errorMsg = "Please select your module file";
        this.failureSnackbar = true;
      }
    },
    fetchModuleFiles() {
      //get all courses
      axios
        .get(`course/${this.currentModuleData.id}/files`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);

          this.moduleFiles = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    filterModuleFiles() {
      //check for search
      if (this.search.length > 0) {
        //check if search matches any data for each course
        this.moduleFiles = this.moduleFiles.filter((file) => {
          return file.name.includes(this.search);
        });
      } else {
        //clear module file list
        this.moduleFiles = [];

        //fetch module files
        this.fetchModuleFiles();
      }
    },
    validateDownload(file) {
      //get link
      var link = file.fileUrl;
      var label = file.name;

      axios
        .get(link, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    validateDelete(file) {
      //set entry
      this.currentModuleFileData = file;

      //open dialog
      this.deleteFileDialog = true;
    },
    deleteFile() {
      //start loading
      this.deletingFile = true;

      //api call
      axios
        .delete(`course/delete-file/${this.currentModuleFileData.id}`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);

          //stop loading
          this.deletingFile = false;

          //show success
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //close dialog
          this.deleteFileDialog = false;

          //clear current entry
          this.currentModuleFileData = {};

          //clear files
          this.moduleFiles = [];

          //fetch module files
          this.fetchModuleFiles();
        })
        .catch((error) => {
          //stop loading
          this.deletingFile = false;

          //log error
          console.log(error.response.data);

          //display error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    validateAssessment(course) {
      //course
      this.currentCourseData = course;

      //navigation
      this.navigationStage = "Assessments";
      this.forwardNavigation();

      //fetch assessments
      this.fetchAssessments();
    },
    fetchAssessments() {
      //get all assessments
      axios
        .get(`course/${this.currentCourseData.id}/assessments`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);

          this.assessments = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    filterAssessments() {
      //check for search
      if (this.search.length > 0) {
        //check if search matches any data for each course
        this.assessments = this.assessments.filter((assessment) => {
          return assessment.label.includes(this.search);
        });
      } else {
        //clear assessment list
        this.assessments = [];

        //fetch assessments
        this.fetchAssessments();
      }
    },
    logAssessmentFile(e) {
      console.log(e);

      //init media
      this.newAssessment.file = e;
    },
    saveStartDate(date) {
      this.$refs.startMenu.saveStartDate(date);
    },
    saveEndDate(date) {
      this.$refs.endMenu.saveEndDate(date);
    },
    validateResults(assessment) {
      //course
      this.currentAssessment = assessment;

      //navigation
      this.navigationStage = "AssessmentResults";
      this.forwardNavigation();

      //fetch results
      this.fetchResults();
    },
    createAssessment() {
      if (this.$refs.createForm.validate() && this.newAssessment.file != null) {
        //start loading
        this.addingAssessment = true;

        //build form
        const formData = new FormData();
        formData.append("category", this.currentCategoryName);
        formData.append("label", this.newAssessment.label);
        formData.append("instructions", this.newAssessment.instructions);
        formData.append(
          "questionsPerHead",
          this.newAssessment.questionsPerHead
        );
        formData.append("duration", this.newAssessment.duration);
        formData.append("startDate", this.newAssessment.startDate);
        formData.append("endDate", this.newAssessment.endDate);
        formData.append("startTime", this.newAssessment.startTime);
        formData.append("endTime", this.newAssessment.endTime);
        formData.append("file", this.newAssessment.file);

        //api call
        axios
          .post(
            `course/${this.currentCourseData.id}/assessment/create`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${this.currentToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.addingAssessment = false;

            //show success
            this.successMsg = "Course module file added successfully";
            this.successSnackbar = true;

            //close dialog
            this.addAssessmentDialog = false;

            //clear temp course data
            this.newAssessment = {};

            //clear files
            this.assessments = [];

            //reset form validation
            this.$refs.createForm.reset();

            //fetch modules
            this.fetchAssessments();
          })
          .catch((error) => {
            //stop loading
            this.addingAssessment = false;

            console.log(error.response.data);
            this.errorMsg = error.response.data.message;
            this.failureSnackbar = true;
          });
      } else {
        this.errorMsg = "Please add the assessment file";
        this.failureSnackbar = true;
      }
    },
    fetchResults() {
      //clear json data
      this.json_data = [];

      //loop through curent assessment results
      this.currentAssessment.results.forEach((result) => {
        //get user
        axios
          .get(`user/get-profile/${result.userId}`, {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          })
          .then((response) => {
            console.log(response.data);

            //get member data
            let member = response.data.data;

            //calculate pass or fail
            var percentage = (result.score * 100) / result.totalQuestions;

            //add object to members list
            this.results.push({
              memberNumber: member.membership.memberNumber,
              firstName: member.firstName,
              lastName: member.lastName,
              email: member.email,
              score: result.score,
              status: percentage >= 55 ? "PASS" : "FAIL",
              answered: result.questionsAnswered,
              submitted: result.dateSubmitted,
            });

            //add object to excell sheet list
            this.json_data.push({
              member: member.membership.memberNumber,
              fname: member.firstName || "",
              lname: member.lastName || "",
              mail: member.email || "",
              score: result.score || "",
              status: percentage >= 55 ? "PASS" : "FAIL",
              answered: result.questionsAnswered || "",
              submitted: result.dateSubmitted || "",
            });
          })
          .catch((error) => {
            console.log(error.data);
          });
      });
    },
    filterResults() {
      //check for search
      if (this.search.length > 0) {
        //check if search matches any data for each course
        this.results = this.results.filter((result) => {
          return (
            result.firstName.includes(this.search) ||
            result.lastName.includes(this.search) ||
            result.memberNumber.includes(this.search) ||
            result.email.includes(this.search)
          );
        });
      } else {
        //clear assessment list
        this.assessments = [];

        //fetch assessments
        this.fetchAssessments();
      }
    },
    forwardNavigation() {
      //check through navigation stage
      switch (this.navigationStage) {
        case "Courses":
          this.categoryListLayout = false;
          this.categoryHomeLayout = true;
          break;

        case "Assessments":
          this.categoryHomeLayout = false;
          this.assessmentHomeLayout = true;
          break;

        case "AssessmentResults":
          this.assessmentHomeLayout = false;
          this.resultLayout = true;
          break;

        case "Modules":
          this.categoryHomeLayout = false;
          this.courseHomeLayout = true;
          break;

        case "Files":
          this.courseHomeLayout = false;
          this.moduleHomeLayout = true;
          break;
      }
    },
    backwardNavigation() {
      //check through navigation stage
      switch (this.navigationStage) {
        case "Courses":
          this.categoryListLayout = true;
          this.categoryHomeLayout = false;
          this.navigationStage = "Home";
          this.currentCategoryName = "";
          break;

        case "Assessments":
          this.categoryHomeLayout = true;
          this.assessmentHomeLayout = false;
          this.navigationStage = "Courses";
          this.currentCourseData = {};
          break;

        case "AssessmentResults":
          this.assessmentHomeLayout = true;
          this.resultLayout = false;
          this.navigationStage = "Assessments";
          this.currentAssessmentData = {};
          break;

        case "Modules":
          this.categoryHomeLayout = true;
          this.courseHomeLayout = false;
          this.navigationStage = "Courses";
          this.currentCourseData = {};
          break;

        case "Files":
          this.courseHomeLayout = true;
          this.moduleHomeLayout = false;
          this.navigationStage = "Modules";
          this.currentModuleData = {};
          break;
      }
    },
    validateEdit(file) {
      //course file
      this.currentModuleFileData = file;
      this.editModuleFileDialog = true;
    },
    logEditModuleFile(e) {
      console.log(e);

      //init media
      this.currentModuleFileData.file = e;
    },
    editModuleFile() {
      //start loading
      this.editingFile = true;

      //build form
      const formData = new FormData();
      formData.append("name", this.currentModuleFileData.name);
      formData.append("author", this.currentModuleFileData.author);
      formData.append("file", this.currentModuleFileData.file);

      //api call
      axios
        .put(`course/edit-file/${this.currentModuleFileData.id}`, formData, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);

          //stop loading
          this.editingFile = false;

          //show success
          this.successMsg = "Course module file updated successfully";
          this.successSnackbar = true;

          //close dialog
          this.editModuleFileDialog = false;

          //clear temp course data
          this.currentModuleFileData = {};

          //clear files
          this.moduleFiles = [];

          //reset form validation
          this.$refs.createForm.reset();

          //fetch modules
          this.fetchModuleFiles();
        })
        .catch((error) => {
          //stop loading
          this.editingFile = false;

          console.log(error.response.data);
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    validateEditAssessment(assessment) {
      //course
      this.currentAssessment = assessment;
      this.editAssessmentDialog = true;
    },
    updateAssessment() {
      //start loading
      this.editingAssessment = true;

      //build form
      const formData = new FormData();
      formData.append("label", this.currentAssessment.label);
      formData.append("instructions", this.currentAssessment.instruction);
      formData.append(
        "questionsPerHead",
        this.currentAssessment.questionsPerHead
      );
      formData.append("duration", this.currentAssessment.duration);
      formData.append("startDate", this.currentAssessment.startDate);
      formData.append("endDate", this.currentAssessment.endDate);
      formData.append("startTime", this.currentAssessment.startTime);
      formData.append("endTime", this.currentAssessment.endTime);

      //api call
      axios
        .put(`course/assessment/${this.currentAssessment.id}/edit`, formData, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);

          //stop loading
          this.editingAssessment = false;

          //show success
          this.successMsg = "Course assessment updated successfully";
          this.successSnackbar = true;

          //close dialog
          this.editAssessmentDialog = false;

          //clear temp course data
          this.currentAssessment = {};

          //clear files
          this.assessments = [];

          //reset form validation
          this.$refs.createForm.reset();

          //fetch modules
          this.fetchAssessments();
        })
        .catch((error) => {
          //stop loading
          this.editingAssessment = false;

          console.log(error.response.data);
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    validateDeleteAssessment(assessment) {
      //course
      this.currentAssessment = assessment;
      this.deleteAssessmentDialog = true;
    },
    deleteAssessment() {
      //start loading
      this.deletingAssessment = true;

      //api call
      axios
        .delete(`course/assessment/${this.currentAssessment.id}/delete`, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);

          //stop loading
          this.deletingAssessment = false;

          //show success
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //close dialog
          this.deleteAssessmentDialog = false;

          //clear current entry
          this.currentAssessment = {};

          //clear assessments
          this.assessments = [];

          //fetch assessments
          this.fetchAssessments();
        })
        .catch((error) => {
          //stop loading
          this.deletingAssessment = false;

          //log error
          console.log(error.response.data);

          //display error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.position-instruction {
  color: #9e9e9e;
}

.new-position {
  display: inline-block;
  text-align: start;

  .the-chip {
    margin-right: 10px;
    display: inline-block;
  }
}

.edit-btn {
  color: #ffffff;
}

.create-btn {
  color: #ffffff;
}

.instruction {
  width: 100%;
  text-align: center;
  align-content: center;
  align-items: center;
}

.avatar {
  text-align: center;
  margin: 20px auto;
}

.extra-btn {
  text-align: center;

  img {
    width: 100%;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.5s ease;

    &:hover {
      opacity: 1;
    }
  }
}

.media-list {
  width: 100%;
  height: auto;
}

.delete-icon {
  color: red;
  position: absolute;
  background: #ffffff;
  cursor: pointer;
}

.page-title {
  text-align: center;
  color: #106b42;
}

.title-indicator {
  padding: 0;
  margin: 20px 0;
  overflow: hidden;

  h2 {
    display: inline-block;
    color: #fff;
    font-size: 13px;
    margin-left: 20px;
    background: #106b42;
    border-radius: 5px;
    padding: 5px;
  }

  .mdi-chevron-right {
    color: #106b42;
    font-size: 14px;
    margin-left: 10px;
  }

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  img {
    vertical-align: middle;
    max-height: 25px;
    max-width: 160px;
    cursor: pointer;
  }
}

.category-list {
  margin-top: 40px;

  .category-img {
    img {
      width: 80%;
      height: auto;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.course-list {
  margin-top: 30px;
}

.text-h4 {
  font-size: 20px !important;
  font-weight: 500;
  color: #106b42;
}
</style>
