<template>
  <v-app-bar app color="white" elevate-on-scroll elevation="4">
    <v-app-bar-nav-icon @click="$emit('handleDrawer')"></v-app-bar-nav-icon>
    <v-spacer />

    <v-spacer></v-spacer>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          style="cursor: pointer"
          class="mx-5 mr-10"
        >
          <v-chip link>
            <v-badge dot bottom offset-y="10" offset-x="10" color="#106b42">
              <v-avatar size="40">
                <img :src="sortAvatar(currentAdmin.avatar)" alt="" />
              </v-avatar>
            </v-badge>
            <span>{{ currentAdmin.username }}</span>
          </v-chip>
        </span>
      </template>
      <v-list width="250" class="py-0">
        <v-list-item two-line>
          <v-list-item-avatar>
            <img :src="sortAvatar(currentAdmin.avatar)" alt="" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ currentAdmin.firstName + " " + currentAdmin.lastName }}
            </v-list-item-title>
            <v-list-item-subtitle> Authenticated </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          v-for="(menu, index) in menus"
          :key="index"
          link
          :disabled="fetchingSettings"
          @click="handleMenuEvent(menu.title)"
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="profileDialog" persistent max-width="600px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <div class="avatar">
                  <v-avatar size="120">
                    <input
                      type="file"
                      ref="file"
                      style="display: none"
                      @change="logFile($event)"
                      accept="image/jpeg, image/png"
                    />

                    <img
                      id="newAvatar"
                      :src="sortAvatar(currentActiveAdmin.avatar)"
                      alt=""
                      @click="$refs.file.click()"
                    />
                  </v-avatar>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveAdmin.firstName"
                  outlined
                  dense
                  label="First Name"
                  required
                  hint="Legal First Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveAdmin.lastName"
                  outlined
                  dense
                  label="Last Name"
                  required
                  hint="Legal Last Name / Surname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveAdmin.username"
                  outlined
                  dense
                  disabled
                  label="Username"
                  type="text"
                  hint="Username"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveAdmin.email"
                  outlined
                  dense
                  disabled
                  label="Email"
                  type="text"
                  hint="Email Address"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentActiveAdmin.position"
                  outlined
                  dense
                  disabled
                  label="Position"
                  type="text"
                  hint="Admin Position"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="(profileDialog = false), (currentActiveAdmin = {})"
          >
            <b>CLOSE</b>
          </v-btn>
          <v-btn
            :loading="updating"
            color="#106b42"
            text
            @click="updateProfile"
          >
            <b>UPDATE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="settingsDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">IIFP Settings</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentSettings.foundation"
                  outlined
                  type="number"
                  dense
                  label="Foundation Subscription Price"
                  required
                  hint="Foundation Subscription Price"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentSettings.technician"
                  type="number"
                  outlined
                  dense
                  label="Technician Subscription Price"
                  required
                  hint="Technician Subscription Price"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentSettings.skill"
                  type="number"
                  outlined
                  dense
                  label="Skill Subscription Price"
                  required
                  hint="Skill Subscription Price"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentSettings.advanced"
                  type="number"
                  outlined
                  dense
                  label="Advanced Subscription Price"
                  required
                  hint="Advanced Subscription Price"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentSettings.professional"
                  type="number"
                  outlined
                  dense
                  label="Professional Subscription Price"
                  required
                  hint="Professional Subscription Price"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="currentSettings.fellow"
                  type="number"
                  outlined
                  dense
                  label="Fellow Subscription Price"
                  required
                  hint="Fellow Subscription Price"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="(settingsDialog = false), (currentSettings = {})"
          >
            <b>CLOSE</b>
          </v-btn>
          <v-btn
            :loading="updatingSettings"
            color="#106b42"
            text
            @click="updateSettings"
          >
            <b>UPDATE</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="changePasswordDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Change Password</span>
        </v-card-title>
        <v-form ref="editForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="payload.oldPassword"
                    :rules="passwordRules"
                    label="Password"
                    :type="showOldPassword ? 'text' : 'password'"
                    placeholder="Enter your password"
                    required
                    prepend-inner-icon="mdi-key"
                    :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showOldPassword = !showOldPassword"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="payload.newPassword"
                    :rules="passwordRules"
                    label="Password"
                    :type="showNewPassword ? 'text' : 'password'"
                    placeholder="Enter your password"
                    required
                    prepend-inner-icon="mdi-key"
                    :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showNewPassword = !showNewPassword"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="payload.confirmPassword"
                    :rules="passwordRules"
                    label="Password"
                    :type="showConfPassword ? 'text' : 'password'"
                    placeholder="Enter your password"
                    required
                    prepend-inner-icon="mdi-key"
                    :append-icon="showConfPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showConfPassword = !showConfPassword"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="(changePasswordDialog = false), (payload = {})"
            >
              <b>CANCEL</b>
            </v-btn>
            <v-btn
              :loading="changingPassword"
              color="#106b42"
              text
              @click="changePassword()"
            >
              <b>CHANGE</b>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </v-app-bar>
</template>

<script>
import axios from "axios";

export default {
  name: "Header",
  data() {
    return {
      currentAdmin: {},
      currentActiveAdmin: {},
      payload: {},
      currentSettings: {},
      currentToken: "",
      imageToUpload: null,
      imageToUploadB64: "",
      loading: false,
      updating: false,
      fetchingSettings: false,
      updatingSettings: false,
      profileDialog: false,
      settingsDialog: false,
      changePasswordDialog: false,
      changingPassword: false,
      showOldPassword: false,
      showNewPassword: false,
      showConfPassword: false,
      successSnackbar: false,
      successMsg: "",
      failureSnackbar: false,
      errorMsg: "",
      menus: [
        { title: "Profile", icon: "mdi-account" },
        { title: "Change Password", icon: "mdi-lock-outline" },
        { title: "Settings", icon: "mdi-cog" },
        { title: "Logout", icon: "mdi-logout" },
      ],
      items: [
        { header: "Today" },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Brunch this weekend?",
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          title: "Oui oui",
          subtitle:
            '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          title: "Birthday gift",
          subtitle:
            '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: "Recipe to try",
          subtitle:
            '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
        },
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 6) || "Password must be 6 characters or more!",
      ],
    };
  },
  created() {
    this.currentAdmin = JSON.parse(localStorage.getItem("user"));
    this.currentToken = localStorage.getItem("token");
  },
  methods: {
    validateProfileDialog() {
      //set data
      this.currentActiveAdmin = this.currentAdmin;

      //open dialog
      this.profileDialog = true;
    },
    logFile(e) {
      console.log(e);
      let image = e.target.files[0];
      let form = new FormData();
      form.append("image", image);
      this.imageToUpload = image;

      //convert to base 64
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        console.log(e.target.result);
        this.sortAvatar(e.target.result);
        this.currentActiveAdmin.avatar = e.target.result;
      };

      //upload
      this.uploadProfilePicture();
    },
    sortAvatar(avatar) {
      if (avatar == null || avatar === "") {
        return "https://firebasestorage.googleapis.com/v0/b/iifp-lms.appspot.com/o/Images%2Favatar.png?alt=media&token=ca7838dc-f13b-41bf-b087-1980283d6f60";
      } else {
        return avatar;
      }
    },
    handleMenuEvent(title) {
      if (title == "Profile") {
        this.validateProfileDialog();
      } else if (title == "Change Password") {
        this.validatePasswordDialog();
      } else if (title == "Settings") {
        this.validateSettingsDialog();
      } else if (title == "Logout") {
        this.logout();
      }
    },
    uploadProfilePicture() {
      //create form
      const formData = new FormData();

      //avatar
      formData.append("avatar", this.imageToUpload);

      //update admin status
      axios
        .put(`admin/avatar`, formData, {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data.message);

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //nullify data
          this.imageToUploadFile = null;
          this.imageToUploadB64 = "";

          //fetch profile
          this.fetchUserData();
        })
        .catch((error) => {
          console.log(error);

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    updateProfile() {
      //start loading
      this.updating = true;

      //update admin status
      axios
        .put(
          `admin/update-profile`,
          {
            firstName: this.currentActiveAdmin.firstName,
            lastName: this.currentActiveAdmin.lastName,
          },
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.updating = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //nullify current action admin
          this.currentActiveAdmin = {};

          //close dialog
          this.profileDialog = false;

          //fetch data
          this.fetchUserData();
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.updating = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    fetchUserData() {
      //check if token is valid
      axios
        .get("admin/profile", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          if (response.data.data.active) {
            //update user
            localStorage.setItem("user", JSON.stringify(response.data.data));

            //update current admin data
            this.currentAdmin = JSON.parse(localStorage.getItem("user"));
          } else {
            //remove user and token
            localStorage.clear();

            //redirect to login
            this.$router.replace("/authenticate");
          }
        })
        .catch((error) => {
          //remove user and token
          localStorage.clear();

          //redirect to login
          this.$router.replace("/authenticate");
        });
    },
    validatePasswordDialog() {
      //set data
      this.payload = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      };

      //open dialog
      this.changePasswordDialog = true;
    },
    changePassword() {
      //check password
      if (
        this.payload.oldPassword.trim() == "" ||
        this.payload.newPassword.trim() == "" ||
        this.payload.confirmPassword.trim() == ""
      ) {
        this.errorMsg = "Required fields empty!";
        this.failureSnackbar = true;
        return;
      }

      //check password
      if (this.payload.oldPassword === this.payload.newPassword) {
        this.errorMsg = "New password cannot be same with old password!";
        this.failureSnackbar = true;
        return;
      }

      //check password
      if (this.payload.newPassword !== this.payload.confirmPassword) {
        this.errorMsg = "New password and confirm password do not match!";
        this.failureSnackbar = true;
        return;
      }

      //start loading
      this.changingPassword = true;

      //update password
      axios
        .put(
          `admin/update-password`,
          {
            oldPassword: this.payload.oldPassword,
            newPassword: this.payload.newPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.changingPassword = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //nullify current action admin
          this.payload = {};

          //close dialog
          this.changePasswordDialog = false;
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.changingPassword = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    validateSettingsDialog() {
      //start loading
      this.fetchingSettings = true;

      //api call
      axios
        .get("settings/get", {
          headers: {
            Authorization: `Bearer ${this.currentToken}`,
          },
        })
        .then((response) => {
          //stop loading
          this.fetchingSettings = false;

          console.log(response.data);

          //populate setting
          this.currentSettings = response.data.data;

          //open dialog
          this.settingsDialog = true;
        })
        .catch((error) => {
          //stop loading
          this.fetchingSettings = false;

          console.log(error.response.data);
        });
    },
    updateSettings() {
      //check if user is super admin
      if (this.currentAdmin.role != "SuperAdmin") {
        this.errorMsg = "You are not authorized to perform this action!";
        this.failureSnackbar = true;
        return;
      }

      //check if all current settings field are numbers
      if (
        isNaN(parseFloat(this.currentSettings.foundation)) ||
        isNaN(parseFloat(this.currentSettings.technician)) ||
        isNaN(parseFloat(this.currentSettings.skill)) ||
        isNaN(parseFloat(this.currentSettings.advanced)) ||
        isNaN(parseFloat(this.currentSettings.professional)) ||
        isNaN(parseFloat(this.currentSettings.fellow))
      ) {
        this.errorMsg = "All prices must be numbers!";
        this.failureSnackbar = true;
        return;
      }

      //start loading
      this.updatingSettings = true;

      //update admin status
      axios
        .post(
          `settings/edit`,
          {
            foundation: this.currentSettings.foundation,
            technician: this.currentSettings.technician,
            skill: this.currentSettings.skill,
            advanced: this.currentSettings.advanced,
            professional: this.currentSettings.professional,
            fellow: this.currentSettings.fellow,
          },
          {
            headers: {
              Authorization: `Bearer ${this.currentToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.message);

          //stop loading
          this.updatingSettings = false;

          //show success message
          this.successMsg = response.data.message;
          this.successSnackbar = true;

          //nullify current settings data
          this.currentSettings = {};

          //close dialog
          this.settingsDialog = false;
        })
        .catch((error) => {
          console.log(error);

          //stop loading
          this.updatingSettings = false;

          //show error message
          this.errorMsg = error.response.data.message;
          this.failureSnackbar = true;
        });
    },
    logout() {
      //remove token from local storage
      localStorage.clear();

      //go home
      this.$router.replace("/");
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
  text-align: center;
  margin: 20px auto;
}

.extra-btn {
  text-align: center;

  img {
    width: 100%;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.5s ease;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
