import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Loading.vue";
import LoginView from "../views/Auth/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import ForgotPassword from "../views/Auth/ForgotPassword.vue";
import ResetPassword from "../views/Auth/ResetPassword.vue";
import Profile from "../views/Profile/Profile.vue";
import Home from "../views/Dash/Home.vue";
import Admins from "../views/Dash/Admins.vue";
import Positions from "../views/Dash/Positions.vue";
import Members from "../views/Dash/Members.vue";
import Registry from "../views/Dash/Registry.vue";
import Advertisments from "../views/Dash/Advertisments.vue";
import Research from "../views/Dash/Research.vue";
import Archive from "../views/Dash/Archive.vue";
import NewsFeed from "../views/Dash/NewsFeed.vue";
import Materials from "../views/Dash/Materials.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Checking",
    component: HomeView,
  },
  {
    path: "/authenticate",
    name: "Authentication",
    component: LoginView,
  },
  {
    path: "/forgot",
    name: "Recover Password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    children: [
      {
        path: "/dashboard/",
        component: Home,
      },
      {
        path: "/dashboard/admins",
        component: Admins,
      },
      {
        path: "/dashboard/positions",
        component: Positions,
      },
      {
        path: "/dashboard/members",
        component: Members,
      },
      {
        path: "/dashboard/newsfeed",
        component: NewsFeed,
      },
      {
        path: "/dashboard/archive",
        component: Archive,
      },
      {
        path: "/dashboard/research",
        component: Research,
      },
      {
        path: "/dashboard/adverts",
        component: Advertisments,
      },
      {
        path: "/dashboard/registry",
        component: Registry,
      },
      {
        path: "/dashboard/materials",
        component: Materials,
        children: [],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
