<template>
  <v-app>
    <div class="background"></div>
    <v-main class="d-flex justify-center align-center">
      <v-col cols="10" lg="4" class="mx-auto">
        <v-card class="pa-4">
          <div class="text-center">
            <v-avatar size="100" color="green lighten-5">
              <img
                class="login-img"
                src="../../assets/images/logo.png"
                alt="IIFP Logo"
              />
            </v-avatar>
            <h2 class="login-title">IIFP Admin Login</h2>
          </div>
          <v-form @submit.prevent="submitHandler" ref="form">
            <v-card-text>
              <v-text-field
                v-model="loginData.email"
                :rules="emailRules"
                type="email"
                label="E-mail"
                placeholder="Enter your e-mail"
                required
                prepend-inner-icon="mdi-account"
              />
              <v-text-field
                v-model="loginData.password"
                :rules="passwordRules"
                label="Password"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Enter your password"
                required
                prepend-inner-icon="mdi-key"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
              <p class="text-right">
                <router-link class="forgot-password" to="forgot"
                  >Forgot Password?</router-link
                >
              </p>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                :loading="loading"
                type="submit"
                class="login-btn"
                color="#106b42"
              >
                <span class="white--text px-8">Authenticate</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-main>
    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginPage",

  data: () => ({
    showPassword: false,
    loading: false,
    successSnackbar: false,
    successMsg: "",
    failureSnackbar: false,
    errorMsg: "",
    loginData: {
      email: "",
      password: "",
    },
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 6) || "Password must be 6 characters or more!",
    ],
  }),
  methods: {
    submitHandler() {
      //check if the form is valid
      if (this.$refs.form.validate()) {
        //start loading
        this.loading = true;

        //api call
        axios
          .post("admin/login", this.loginData)
          .then((response) => {
            console.log(response.data);

            //check if user is active
            if (response.data.admin.active) {
              //stop loading
              this.loading = false;

              //show toast
              this.successSnackbar = true;
              this.successMsg = response.data.message;

              //save token to local
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("user", JSON.stringify(response.data.admin));

              //go to dashboard
              setTimeout(() => {
                this.$router.replace("/dashboard");
              }, 2000);
            } else {
              //stop loading
              this.loading = false;

              //show error
              this.errorMsg = "Your account has been deactivated!";
              this.failureSnackbar = true;
            }
          })
          .catch((error) => {
            console.log(error.response.data);

            //stop loading
            this.loading = false;

            //show toast
            this.failureSnackbar = true;
            this.errorMsg = error.response.data.message;
          });
      }
    },
  },
};
</script>
<style scoped>
.background {
  background-image: url(../../assets/images/iifp_board.jpeg) !important;
  height: 100vh;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.login-img {
  width: 60%;
  height: auto;
}

.login-title {
  color: #106b42;
  margin-top: 20px;
}

.login-btn {
  color: #106b42;
}

.toast-msg {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.forgot-password {
  font-size: 16px;
  color: #106b42 !important;
  font-weight: bold;
  text-decoration: none;
}
</style>
